import { Phone } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function Hero() {
  const [showSecondImage, setShowSecondImage] = useState(false);

  useEffect(() => {
    // Toggle between images every 12 seconds
    const interval = setInterval(() => {
      setShowSecondImage(prev => !prev);
    }, 12000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="home" className="relative min-h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0">
        <div className="relative w-full h-full overflow-hidden">
          {/* Landscape photo */}
          <picture className={`absolute w-full h-full transition-opacity duration-1000 ${
            showSecondImage ? 'opacity-0' : 'opacity-100'
          }`}>
            <source
              srcSet="/images/a-serene-landscape-photo-with-majestic-mountains-optimized.webp"
              type="image/webp"
            />
            <img
              src="/images/a-serene-landscape-photo-with-majestic-mountains.png"
              alt="Serene landscape with majestic mountains"
              className="w-full h-full object-cover animate-kenburns"
            />
          </picture>
          
          {/* Waterfall photo */}
          <picture className={`absolute w-full h-full transition-opacity duration-1000 ${
            showSecondImage ? 'opacity-100' : 'opacity-0'
          }`}>
            <source
              srcSet="/images/a-serene-photo-of-a-cascading-waterfall-optimized.webp"
              type="image/webp"
            />
            <img
              src="/images/a-serene-photo-of-a-cascading-waterfall.png"
              alt="Serene cascading waterfall"
              className="w-full h-full object-cover animate-kenburns"
            />
          </picture>
          
          <div className="absolute inset-0 bg-brand-teal-dark/30"></div>
        </div>
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6">
            Private Counselling Services in Surrey.
          </h1>
          <p className="text-xl text-white mb-8">
            Professional counselling services in a safe, supportive environment. 
            Let's work together to help you find clarity, healing, and growth.
          </p>
          <div className="flex flex-col items-center">
            <p className="text-xl text-white mb-4">
              Call us today to book your consultation
            </p>
            <a 
              href="tel:604-619-9992"
              className="inline-flex items-center justify-center px-8 py-4 bg-brand-teal hover:bg-brand-teal-dark text-white font-medium rounded-lg transition-colors text-xl"
            >
              <Phone className="mr-2 h-5 w-5" />
              604-619-9992
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}