import { Link } from 'react-router-dom';
import {
  Brain,
  CloudRain,
  Anchor,
  Heart,
  Users,
  FlowerIcon,
  Sparkles,
  Rainbow,
} from 'lucide-react';

export default function TherapiesGrid() {
  const therapies = [
    {
      icon: Brain,
      title: 'Anxiety & Stress',
      description: 'Professional support for managing anxiety and stress in Surrey',
      link: '/anxiety-stress-management-counselor-in-surrey',
    },
    {
      icon: CloudRain,
      title: 'Depression',
      description: 'Expert counselling for depression treatment in Surrey',
      link: '/depression-treatment-counsellors-in-surrey',
    },
    {
      icon: Anchor,
      title: 'Post-Traumatic Stress',
      description: 'Specialized trauma-informed care and PTSD support',
      link: '/post-traumatic-stress-disorder-counsellors',
    },
    {
      icon: Heart,
      title: 'Anger Management',
      description: 'Professional anger management counselling in Surrey',
      link: '/anger-management-counselling-surrey',
    },
    {
      icon: Sparkles,
      title: "Women's Issues",
      description: "Specialized counselling for women's unique challenges in Surrey",
      link: '/womens-issues-counsellors-in-surrey',
    },
    {
      icon: FlowerIcon,
      title: 'Grief Therapy',
      description: 'Compassionate grief and loss counselling services',
      link: '/grief-counselling-support-surrey',
    },
    {
      icon: Users,
      title: 'Family Therapy',
      description: 'Building stronger family connections in Surrey',
      link: '/family-therapy-counselling-services-in-surrey',
    },
    {
      icon: Rainbow,
      title: 'LGBTQ+ Counselling',
      description: 'Affirming therapy for the LGBTQ+ community in Surrey',
      link: '/lgbtq-counselling',
    },
  ];

  return (
    <div className="bg-gray-50 py-12 mt-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">
            Our Therapy Services
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Explore our range of specialized therapy services
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {therapies.map((therapy, index) => (
            <Link
              key={index}
              to={therapy.link}
              className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
            >
              <therapy.icon className="h-8 w-8 text-brand-teal mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {therapy.title}
              </h3>
              <p className="text-gray-600">{therapy.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
