import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Meta from './Meta';

interface CounsellorData {
  name: string;
  title: string;
  image: string;
  languages: string[];
  description: string;
}

const counsellorData: { [key: string]: CounsellorData } = {
  'kristina-sohal': {
    name: "Kristina Sohal",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Kristina_Sohal-optimized.webp",
    languages: ["English", "Punjabi"],
    description: `I'm Kristina, a registered counsellor in Vancouver who helps transform lives through compassionate and non-judgmental therapy.

**My Journey**
My path to counselling began in the finance industry, where I worked for several years. While helping clients with financial stress, I discovered my true passion for supporting people through their challenges. This led me to pursue a career in therapy, earning my Masters of Counselling Psychology from Yorkville University.

**My Approach**
As a member of the British Columbia Association of Counsellors, I take pride in:
• Providing a safe, non-judgmental space for healing
• Taking time to truly listen and understand each client's unique journey
• Offering personalized tools for positive life changes

**Therapeutic Methods**
I employ various evidence-based approaches including:
• Cognitive Behavioural Therapy (CBT)
• Mindful Exercises
• Solution-Focused Therapy
• Person Centered Therapy
• Exposure Therapy
• Empty Chair Techniques`,
  },
  'minoo-shayesteh': {
    name: "Minoo Shayesteh",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Minoo-optimized.webp",
    languages: ["English", "Persian", "French (Intermediate)"],
    description: `**Professional Background**
As a clinical counselor and psychologist, I bring extensive experience in helping individuals navigate life's challenges. I am a registered clinical counselor with the British Columbia Association of Clinical Counselors (BCACC), specializing in:
• Concurrent disorders
• Mood disorders
• Trauma healing
• Concussion management

**Therapeutic Approaches**
I utilize evidence-based modalities including:
• Cognitive Behavioral Therapy (CBT)
• Dialectical Behavior Therapy (DBT)
• Acceptance and Commitment Therapy (ACT)
• Solution-Focused Therapy
• Eye Movement Desensitization and Reprocessing (EMDR)

**Areas of Focus**
My practice supports clients with:
• Trauma recovery
• Depression and anxiety
• Substance abuse
• Family dynamics
• Interpersonal relationships
• Stress management

My passion lies in helping individuals heal from traumatic experiences and develop healthy self-perceptions, guiding them toward peace, completeness, and safety.`,
  },
  'cynthia-csepregi': {
    name: "Cynthia Csepregi",
    title: "RCC, BA in Social Work",
    image: "/images/Cynthia-Csepregi-min-optimized.webp",
    languages: ["English"],
    description: `**Welcome**
I'm Cynthia, and I'm excited to be part of the Metro Counselling team. As a registered Social Worker and Counsellor, I believe strongly in people's capacity for positive change.

**Professional Journey**
My career path includes:
• 14 years in complex mental health settings
• Extensive work in non-profit organizations
• Experience with developmental disabilities programs
• Masters in Counselling from City University of Seattle
• Specialized research in the impact of suicide on families

**Counselling Philosophy**
I bring to my practice:
• A strengths-based approach
• Extensive mental health experience
• Deep empathy and understanding
• Commitment to supporting positive change
• Specialized crisis intervention training

My greatest satisfaction comes from helping clients become architects of change in their own lives. I look forward to being part of your journey toward positive transformation.`,
  },
  'fatemeh-poorrezvan': {
    name: "Fatemeh Poorrezvan",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/venus1-optimized.webp",
    languages: ["English", "Farsi", "Turkish"],
    description: `**Professional Credentials**
• BCACC Registered Clinical Counselor
• Member of the American Psychological Association (APA)
• Master's degree in Clinical Psychology
• Specialized training in Autism and Neurodiversity
• Expertise in Addiction Management

**Research & Experience**
My research has focused on:
• Hope and expectation in medical settings
• Behavioral discipline and self-efficacy
• Anxiety management in surgical patients
• 13+ years of clinical consultation experience
• Extensive work with diverse patient populations

**Clinical Approach**
I am inspired by:
• Human resilience and healing potential
• Client-centered therapy
• Evidence-based practices
• Collaborative treatment planning
• Multicultural counselling perspectives

Through my hospital experience and work with thousands of patients, I've developed a deep appreciation for the human capacity for growth and healing.`,
  },
  'rafael-tejada': {
    name: "Rafael Tejada",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/rafeal-optimized.webp",
    languages: ["English", "Spanish"],
    description: `**Areas of Expertise**
I specialize in helping clients navigate:
• Trauma and recovery
• Anxiety and depression
• Personality disorders
• Addiction challenges
• Relationship dynamics
• Family communication

**Therapeutic Focus**
My work centers on:
• Examining attachment difficulties
• Healing relationship injuries
• Building self-understanding
• Developing healthy connections
• Restoring self-esteem and hope

**Counselling Philosophy**
I believe in:
• Facing challenges with kindness
• Building compassionate perspectives
• Embracing our whole selves
• Working collaboratively toward goals
• Creating lasting positive change

Together, we'll work to understand your experiences and build a path toward inner peace and stronger relationships.`,
  },
  'beth-mares': {
    name: "Beth Mares",
    title: "Self-Care Coach & Therapeutic Touch Practitioner",
    image: "/images/beth_hq-optimized.webp",
    languages: ["English"],
    description: `Beth is a seasoned Self-Care Coach and Therapeutic Touch Practitioner with extensive experience in Quantum and Ancient Healing Modalities. Specializing in Holistic Bodywork, Massage, and Therapeutic Touch Techniques, Beth's practice blends scientific rigor with spiritual wisdom.

With her compassionate touch, Beth harmonizes individuals' energy fields, aiming to balance and clear their bio-energetic systems. She has spent years working in medical settings, helping diverse clients heal from chronic health conditions. Beth is convinced that energy work and self-care are pivotal for the future of medicine.`,
  },
};

const CounsellorBio = () => {
  const { counsellorId } = useParams<{ counsellorId: string }>();
  const counsellor = counsellorId ? counsellorData[counsellorId] : null;
  const navigate = useNavigate();

  const handleBackClick = () => {
    // Navigate to home page
    navigate('/');
    // Wait for navigation to complete then scroll to counsellors section
    setTimeout(() => {
      const counsellorsSection = document.querySelector('.bg-white.py-12');
      if (counsellorsSection) {
        counsellorsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  if (!counsellor) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Counsellor not found</h2>
          <Link to="/" className="mt-4 text-blue-600 hover:text-blue-800">
            Return to Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Meta 
        title={`${counsellor.name} - ${counsellor.title} | Metro Counselling Surrey`}
        description={`Meet ${counsellor.name}, ${counsellor.title} at Metro Counselling. ${counsellor.description.split('\n')[0]}`}
      />
      
      {/* Hero Section */}
      <div className="bg-gradient-to-b from-blue-50 to-white pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="flex flex-col lg:flex-row items-start gap-12">
            {/* Image Column */}
            <div className="w-full lg:w-80 flex-shrink-0">
              <div className="rounded-lg overflow-hidden shadow-md bg-white">
                <img
                  src={counsellor.image}
                  alt={counsellor.name}
                  className="w-full h-96 object-cover object-top"
                />
              </div>
              
              {/* Languages Section - Moved under image on mobile */}
              <div className="mt-6 bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Languages</h3>
                <div className="flex flex-wrap gap-2">
                  {counsellor.languages.map((language) => (
                    <span
                      key={language}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                    >
                      {language}
                    </span>
                  ))}
                </div>
              </div>

              {/* Contact Button */}
              <Link
                to="/contact-us"
                className="mt-6 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
              >
                Book an Appointment
              </Link>
            </div>

            {/* Content Column */}
            <div className="flex-grow">
              <h1 className="text-4xl font-bold text-gray-900 mb-2">{counsellor.name}</h1>
              <p className="text-xl text-gray-600 mb-8">{counsellor.title}</p>
              
              <div className="prose prose-lg max-w-none">
                <ReactMarkdown
                  components={{
                    p: (props) => <p className="mb-6 text-gray-700" {...props} />,
                    strong: (props) => <strong className="text-gray-900 font-semibold" {...props} />,
                    ul: (props) => <ul className="list-disc pl-6 mb-6" {...props} />,
                    li: (props) => <li className="text-gray-700 mb-2" {...props} />,
                    h2: (props) => <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4" {...props} />
                  }}
                >
                  {counsellor.description}
                </ReactMarkdown>
              </div>

              {/* Navigation */}
              <div className="mt-12 flex items-center justify-between border-t border-gray-200 pt-8">
                <button
                  onClick={handleBackClick}
                  className="mb-6 px-4 py-2 text-gray-600 hover:text-gray-800 flex items-center gap-2 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L4.414 9H17a1 1 0 110 2H4.414l5.293 5.293a1 1 0 010 1.414z" clipRule="evenodd" />
                  </svg>
                  Back to Counsellors
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounsellorBio;
