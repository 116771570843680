import { Mail, Phone, MapPin } from 'lucide-react';
import Meta from '../components/Meta';

interface OfficeLocation {
  name: string;
  address: string[];
  city: string;
  postalCode?: string;
  mapUrl?: string;
}

export default function Contact() {
  const offices: OfficeLocation[] = [
    {
      name: "Surrey Main Office",
      address: ["201-10366 136A street"],
      city: "Surrey",
      postalCode: "V3T 1W4",
      mapUrl: "https://www.google.com/maps/dir/?api=1&destination=10366+136A+St+Surrey+BC+V3T+1W4"
    },
    {
      name: "Vancouver Office",
      address: ["1055 West Hastings"],
      city: "Vancouver",
      postalCode: ""
    },
    {
      name: "Burnaby Office",
      address: ["7472 19th Ave"],
      city: "Burnaby",
      postalCode: ""
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Meta 
        title="Contact Metro Counselling | Surrey, Vancouver & Burnaby Locations"
        description="Contact Metro Counselling for professional counselling services in Surrey, Vancouver, and Burnaby. Book your consultation today at 604-619-9992."
      />
      
      {/* Hero Section */}
      <div className="relative pt-12">
        <div 
          className="absolute inset-0 bg-cover bg-center h-[300px]"
          style={{
            backgroundImage: "url('https://images.unsplash.com/photo-1497215842964-222b430dc094?auto=format&fit=crop&w=1920&q=75&fm=webp')",
          }}
        >
          {/* Minimal gradient overlay to maintain text readability while showing more image */}
          <div className="absolute inset-0 bg-gradient-to-b from-white/20 via-white/25 to-white/70"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 drop-shadow">
              Contact Metro Counselling in Surrey
            </h1>
            <p className="text-xl font-semibold text-brand-teal mb-4 drop-shadow">
              Professional Counselling Services in Surrey, Vancouver & Burnaby
            </p>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto bg-white/40 backdrop-blur rounded-lg p-4 shadow-sm">
              Schedule your consultation today and take the first step towards positive change. 
              Our experienced counsellors are here to support your mental health journey.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Get in Touch with Our Counselling Team
            </h2>
            <div className="space-y-6">
              <div className="flex items-start">
                <Phone className="h-6 w-6 text-brand-teal mr-4 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900">Phone</h3>
                  <div className="flex items-center gap-2">
                    <a 
                      href="tel:604-619-9992"
                      className="text-gray-600 hover:text-brand-teal transition-colors"
                    >
                      604-619-9992
                    </a>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText('604-619-9992');
                        // You could add a toast notification here
                      }}
                      className="text-sm text-brand-teal hover:text-brand-teal-dark transition-colors"
                      title="Copy phone number"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </button>
                    <span className="text-sm text-gray-500">(Click to call on mobile)</span>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <Mail className="h-6 w-6 text-brand-teal mr-4 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900">Email</h3>
                  <div className="flex items-center gap-2">
                    <a 
                      href="mailto:info@metrocounselling.ca" 
                      className="text-gray-600 hover:text-brand-teal"
                    >
                      info@metrocounselling.ca
                    </a>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText('info@metrocounselling.ca');
                        // You could add a toast notification here
                      }}
                      className="text-sm text-brand-teal hover:text-brand-teal-dark transition-colors"
                      title="Copy email address"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <Phone className="h-6 w-6 text-brand-teal mr-4 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-900">Fax</h3>
                  <div className="flex items-center gap-2">
                    <a 
                      href="tel:778-653-7878"
                      className="text-gray-600 hover:text-brand-teal transition-colors"
                    >
                      778-653-7878
                    </a>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText('778-653-7878');
                        // You could add a toast notification here
                      }}
                      className="text-sm text-brand-teal hover:text-brand-teal-dark transition-colors"
                      title="Copy fax number"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-12">
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">
                Our Offices
              </h2>
              <div className="space-y-8">
                {offices.map((office, index) => (
                  <div key={index} className="bg-white rounded-xl shadow-sm p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {office.name}
                    </h3>
                    <div className="flex items-start">
                      <MapPin className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                      <div>
                        {office.address.map((line, i) => (
                          <p key={i} className="text-gray-600">{line}</p>
                        ))}
                        <p className="text-gray-600">
                          {office.city}, BC{office.postalCode ? `, ${office.postalCode}` : ''}
                        </p>
                        {office.mapUrl && (
                          <a
                            href={office.mapUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center mt-2 text-brand-teal hover:text-brand-teal-dark"
                          >
                            Get Directions
                            <svg className="w-4 h-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Map Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Surrey Main Office Location
            </h2>
            <div className="rounded-xl overflow-hidden shadow-sm h-[600px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.477226766378!2d-122.84752372368517!3d49.19124197932091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d766c7fdf395%3A0x8bf0549f1e166c93!2s10366%20136a%20St%2C%20Surrey%2C%20BC%20V3T%201W4!5e0!3m2!1sen!2sca!4v1699482163145!5m2!1sen!2sca"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="mt-4 text-center">
              <a
                href="https://www.google.com/maps/dir/?api=1&destination=10366+136A+St+Surrey+BC+V3T+1W4"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-teal hover:bg-brand-teal-dark transition-colors duration-200"
              >
                Get Directions to Surrey Office
                <MapPin className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
