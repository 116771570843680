import { Heart, Brain, Shield, Users, CheckCircle2, Star } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';

export default function Depression() {
  const approaches = [
    {
      icon: Heart,
      title: 'Holistic Care',
      description: 'Addressing emotional, physical, and social aspects of well-being.',
    },
    {
      icon: Users,
      title: 'Support System',
      description: 'Building and strengthening your support network.',
    },
    {
      icon: Brain,
      title: 'Life Direction',
      description: 'Finding purpose and meaning in your journey forward.',
    },
    {
      icon: Shield,
      title: 'Evidence-Based Treatment',
      description: 'Using proven therapeutic methods to help you overcome depression.',
    },
  ];

  const symptoms = [
    {
      category: "Emotional Signs",
      items: [
        "Persistent sadness or empty feelings",
        "Loss of interest in activities",
        "Feelings of worthlessness",
        "Difficulty experiencing joy"
      ]
    },
    {
      category: "Physical Signs",
      items: [
        "Changes in sleep patterns",
        "Appetite or weight changes",
        "Fatigue or loss of energy",
        "Physical aches and pains"
      ]
    },
    {
      category: "Behavioral Signs",
      items: [
        "Social withdrawal",
        "Difficulty concentrating",
        "Procrastination or neglecting responsibilities",
        "Changes in daily routines"
      ]
    }
  ];

  return (
    <div className="pt-16">
      {/* Hero Section */}
      <div className="relative h-[500px] mb-12">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('https://images.unsplash.com/photo-1518495973542-4542c06a5843?auto=format&fit=crop&w=1920&q=75&fm=webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/70"></div>
        </div>
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Depression Counselling in Surrey, BC
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mb-4">
            Professional Support for Depression Treatment
          </p>
          <a 
            href="tel:604-619-9992" 
            className="text-2xl md:text-3xl text-white font-medium hover:text-brand-teal transition-colors"
          >
            604-619-9992
          </a>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {approaches.map((approach, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm p-8">
                <approach.icon className="h-8 w-8 text-brand-teal mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {approach.title}
                </h3>
                <p className="text-gray-600">{approach.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-brand-teal mb-6">
              Understanding Depression
            </h2>
            <p className="text-gray-600 mb-6">
              Depression often sneaks in gradually, taking on a life of its own before you realize what's happening. 
              While you might sense something isn't right, you may not immediately recognize depression as the underlying cause. 
              Sometimes, it's family or friends who first notice the changes in your behavior and personality.
            </p>
            <p className="text-gray-600 mb-6">
              Clinical depression is more than just feeling sad or going through a rough patch. It's a serious mental 
              health condition that affects how you think, feel, and handle daily activities. At Metro Counselling, 
              we understand that each person's experience with depression is unique, and we're here to help you navigate 
              your path to recovery.
            </p>
            <div className="bg-gray-50 rounded-lg p-6 mb-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                The Impact of Depression
              </h3>
              <p className="text-gray-600 mb-4">
                If you're experiencing a persistent heaviness affecting your life, finding it impossible to "just get over things," 
                or constantly dwelling on negative thoughts, depression might be the underlying cause. Despite its prevalence, 
                depression remains misunderstood by many, making it crucial to seek professional help when you're ready for change.
              </p>
            </div>
          </div>

          <div className="bg-gray-50 rounded-xl p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Signs and Symptoms
            </h2>
            <p className="text-gray-600 mb-6">
              According to the Anxiety and Depression Association of America (ADAA), depression manifests through various symptoms 
              that can significantly impact daily life. Recognizing these signs is the first step toward recovery.
            </p>
            <div className="grid md:grid-cols-3 gap-8">
              {symptoms.map((group, index) => (
                <div key={index} className="bg-white rounded-xl shadow-sm p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    {group.category}
                  </h3>
                  <ul className="space-y-3">
                    {group.items.map((item, itemIndex) => (
                      <li key={itemIndex} className="flex items-start">
                        <CheckCircle2 className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                        <span className="text-gray-600">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              The Path to Recovery
            </h2>
            <div className="grid md:grid-cols-2 gap-8 mb-8">
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Benefits of Professional Support
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">More regular, restful sleep patterns</span>
                  </li>
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">Improved relationships with yourself and others</span>
                  </li>
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">Enhanced memory and mental clarity</span>
                  </li>
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">Increased energy and engagement in life</span>
                  </li>
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">Reduction in physical discomfort</span>
                  </li>
                  <li className="flex items-start">
                    <Star className="h-5 w-5 text-brand-teal mr-2 mt-1" />
                    <span className="text-gray-600">Improved work performance and focus</span>
                  </li>
                </ul>
              </div>
              <div className="bg-gray-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Our Approach to Treatment
                </h3>
                <p className="text-gray-600 mb-4">
                  At Metro Counselling, with over 20 years of experience working with depression, we've created a safe and 
                  supportive environment for your healing journey. Our approach combines proven therapeutic techniques with 
                  compassionate support to help you rediscover hope and possibility.
                </p>
                <p className="text-gray-600">
                  Many people find relief through a combination of therapy and other treatment options. We work with you to 
                  develop a personalized treatment plan that addresses your unique needs and circumstances.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Start Your Journey to Recovery Today
            </h2>
            <p className="text-gray-600 mb-8 text-lg">
              Take the first step towards healing. Our experienced counsellors are here to provide the support and guidance you need to overcome depression and rediscover joy in your life.
            </p>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </div>

          <TherapiesGrid />
        </div>
      </div>
    </div>
  );
}
