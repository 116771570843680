import { Heart, Star, Shield, Users } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';
import { useEffect } from 'react';
import { LucideIcon } from 'lucide-react';

interface ServiceCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

function ServiceCard({ icon: Icon, title, description }: ServiceCardProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
      <Icon className="h-10 w-10 text-brand-teal mb-4" />
      <h3 className="text-xl font-semibold text-gray-900 mb-3">{title}</h3>
      <p className="text-gray-600 leading-relaxed">{description}</p>
    </div>
  );
}

export default function LGBTQCounselling() {
  useEffect(() => {
    // Update document title
    document.title = 'LGBTQ+ Counselling in Surrey | Metro Counselling';

    // Update or create meta description
    const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
    if (!metaDescription) {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = 'Compassionate LGBTQ+ counselling services in Surrey for both adults and teens. Our experienced counsellors provide a safe, affirming space for exploring identity and promoting well-being.';
      document.head.appendChild(meta);
    } else {
      metaDescription.content = 'Compassionate LGBTQ+ counselling services in Surrey for both adults and teens. Our experienced counsellors provide a safe, affirming space for exploring identity and promoting well-being.';
    }

    // Update or create meta keywords
    const metaKeywords = document.querySelector('meta[name="keywords"]') as HTMLMetaElement | null;
    if (!metaKeywords) {
      const meta = document.createElement('meta');
      meta.name = 'keywords';
      meta.content = 'LGBTQ counselling Surrey, LGBT therapy, transgender counselling, gay counselling, queer therapy, LGBT teen support, LGBTQ mental health';
      document.head.appendChild(meta);
    } else {
      metaKeywords.content = 'LGBTQ counselling Surrey, LGBT therapy, transgender counselling, gay counselling, queer therapy, LGBT teen support, LGBTQ mental health';
    }
  }, []);

  const services = [
    {
      icon: Heart,
      title: 'Identity Exploration',
      description: 'A safe space to explore gender identity and sexual orientation with understanding and support.',
    },
    {
      icon: Star,
      title: 'Personal Growth',
      description: 'Guidance for self-acceptance, authenticity, and building confidence in who you are.',
    },
    {
      icon: Shield,
      title: 'Relationship Support',
      description: 'Help navigating relationships, family dynamics, and building meaningful connections.',
    },
    {
      icon: Users,
      title: 'Community Connection',
      description: 'Resources and support for connecting with the LGBTQ+ community and allies.',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-16">
      {/* Hero Section */}
      <div className="relative h-[500px] mb-16">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('/images/optimized/lgbtq.webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/70"></div>
        </div>
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            LGBTQ+ Counselling in Surrey, BC
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mb-4">
            Professional Support for the LGBTQ+ Community
          </p>
          <a 
            href="tel:604-619-9992" 
            className="text-2xl md:text-3xl text-white font-medium hover:text-brand-teal transition-colors"
          >
            604-619-9992
          </a>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Introduction */}
        <div className="max-w-4xl mx-auto text-center mb-16">
          <p className="text-lg md:text-xl text-gray-700 leading-relaxed">
            At Metro Counselling, we understand the unique challenges faced by the LGBTQ+ community. Whether you're exploring your identity, facing societal pressures, or seeking support in your journey, our experienced counsellors provide a safe, accepting environment where you can be authentically yourself.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        {/* Adults Section */}
        <section className="mb-16">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="p-8 lg:p-12">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">For Adults</h2>
                <div className="prose prose-lg">
                  <p className="mb-6">
                    Are you questioning your identity or feeling anxious about acceptance? Many LGBTQ+ adults experience feelings of isolation, confusion, or anxiety. We want you to know that you're not alone, and feeling good about who you are is essential for a fulfilling life.
                  </p>
                  <p className="mb-6">
                    Our counselling provides a confidential space where you can:
                  </p>
                  <ul className="space-y-3">
                    <li>Explore your identity in a safe, supportive environment</li>
                    <li>Process feelings about coming out or transitioning</li>
                    <li>Navigate relationships and family dynamics</li>
                    <li>Build resilience against discrimination and minority stress</li>
                    <li>Develop self-acceptance and confidence</li>
                  </ul>
                </div>
              </div>
              <div className="relative h-full min-h-[300px] md:min-h-full">
                <img
                  src="/images/lgbtq-3.jpg"
                  alt="LGBTQ+ Adult Counselling"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Teens Section */}
        <section className="mb-16">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="grid md:grid-cols-2 gap-8">
              <div className="relative h-full min-h-[300px] md:min-h-full order-2 md:order-1">
                <img
                  src="/images/lgbtq1.jpg"
                  alt="LGBTQ+ Teen Support"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-8 lg:p-12 order-1 md:order-2">
                <h2 className="text-3xl font-bold text-gray-900 mb-6">For Teens</h2>
                <div className="prose prose-lg">
                  <p className="mb-6">
                    Being a teenager is challenging enough, and exploring your identity can add another layer of complexity. We provide specialized support for LGBTQ+ teens in a safe, understanding environment.
                  </p>
                  <p className="mb-6">
                    Our teen counselling services include:
                  </p>
                  <ul className="space-y-3">
                    <li>Support for identity exploration and self-discovery</li>
                    <li>Coping strategies for school and social situations</li>
                    <li>Help with family relationships and communication</li>
                    <li>Building self-esteem and resilience</li>
                    <li>Connection to LGBTQ+ youth resources and community</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <div className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Start Your Journey Today
          </h2>
          <p className="text-gray-600 mb-8 text-lg">
            Take the first step towards a more authentic and fulfilling life. Our experienced counsellors provide a safe, affirming space for you to explore your identity and work through challenges.
          </p>
          <div className="flex flex-col items-center">
            <p className="text-xl font-semibold text-gray-900 mb-4">
              Call us to book your consultation
            </p>
            <a 
              href="tel:604-619-9992" 
              className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
            >
              604-619-9992
            </a>
          </div>
        </div>

        <TherapiesGrid />
      </div>
    </div>
  );
}
