import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import { useAuth } from './hooks/useAuth';
import { supabase } from './supabase';
import { toast } from 'sonner';
import TextAlign from '@tiptap/extension-text-align'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import Paragraph from '@tiptap/extension-paragraph';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import CodeBlock from '@tiptap/extension-code-block';
import Blockquote from '@tiptap/extension-blockquote';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import HardBreak from '@tiptap/extension-hard-break';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  content: string;
  description: string;
  keywords: string;
  published_at: string;
  image_url: string;
  image_alt_text: string;
  created_at: string;
  updated_at: string;
}

export default function BlogAdmin() {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [isPublished, setIsPublished] = useState(false);

  const [post, setPost] = useState<Partial<BlogPost>>({
    title: '',
    slug: '',
    content: '',
    description: '',
    keywords: '',
    image_url: '',
    image_alt_text: '',
  });

  const extensions = useMemo(() => [
    StarterKit.configure({
      heading: {
        levels: [1, 2, 3]
      }
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Bold,
    Italic,
    Strike,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    CodeBlock,
    Blockquote,
    HorizontalRule,
    HardBreak,
    TextStyle,
    Color,
    Image.configure({
      inline: true,
      allowBase64: true,
    }),
  ], []);

  const editor = useEditor({
    extensions,
    content: '',
    onCreate: ({ editor }) => {
      if (post.content) {
        editor.commands.setContent(post.content);
      }
    },
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content !== post.content) {
        setPost(prev => ({ ...prev, content }));
      }
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-xl focus:outline-none max-w-none',
      },
    },
  }, []);

  // Cleanup editor on unmount
  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  // Update editor content when post changes
  useEffect(() => {
    if (editor && post.content !== undefined && !editor.isDestroyed) {
      const currentContent = editor.getHTML();
      if (currentContent !== post.content) {
        editor.commands.setContent(post.content);
      }
    }
  }, [post.content, editor]);

  const buttonStyle = `
    px-3 py-1.5 rounded text-sm font-medium transition-colors
    hover:bg-gray-700 text-gray-100 border border-transparent
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal
    disabled:opacity-50 disabled:cursor-not-allowed
  `;
  
  const activeButtonStyle = `
    px-3 py-1.5 rounded text-sm font-medium transition-colors
    bg-brand-teal text-white border border-brand-teal/20
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  const fetchPost = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        setPost(data);
        if (data.image_url) {
          try {
            const { data: signedUrl } = await supabase
              .storage
              .from('blog-images')
              .createSignedUrl(data.image_url.replace('blog-images/', ''), 3600);
            
              setImagePreview(signedUrl?.signedUrl || '');
          } catch (error) {
            console.error('Error getting signed URL:', error);
            setImagePreview(data.image_url);
          }
        }
        setIsPublished(!!data.published_at);
        editor?.commands.setContent(data.content || '');
      }
    } catch (error) {
      toast.error('Error fetching post');
      console.error('Error:', error);
    }
  }, [id, editor]);

  const fetchPosts = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .order('published_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      toast.error('Error fetching posts');
    }
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    if (id) {
      fetchPost();
    } else {
      fetchPosts();
    }
  }, [id, user, navigate, fetchPost, fetchPosts]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    const fileExt = file.name.split('.').pop();
    // Remove the extension from the original name before cleaning
    const nameWithoutExt = file.name.substring(0, file.name.lastIndexOf('.'));
    const cleanFileName = nameWithoutExt
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')
      .substring(0, 50);
    const fileName = `${Date.now()}-${cleanFileName}.${fileExt}`;

    const { error: uploadError } = await supabase.storage
      .from('blog-images')
      .upload(fileName, file);

    if (uploadError) {
      throw uploadError;
    }

    return fileName;
  };

  const generateSlug = (title: string) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')
      .substring(0, 100); // Limit slug length
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setLoading(true);
      let imageUrl = post.image_url;

      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      }

      const timestamp = new Date().toISOString();
      const postData = {
        title: post.title,
        slug: post.slug || generateSlug(post.title || ''),
        content: post.content,
        description: post.description || null,
        keywords: post.keywords || null,
        image_url: imageUrl || null,
        image_alt_text: post.image_alt_text || null,
        updated_at: timestamp,
        created_at: id ? post.created_at : timestamp,
        published_at: isPublished ? timestamp : null,
      };

      // Check if slug already exists (for new posts)
      if (!id) {
        const { data: existingPost } = await supabase
          .from('posts')
          .select('id')
          .eq('slug', postData.slug)
          .single();

        if (existingPost) {
          // Append a timestamp to make the slug unique
          postData.slug = `${postData.slug}-${Date.now()}`;
        }
      }

      const { error } = id
        ? await supabase
            .from('posts')
            .update(postData)
            .eq('id', id)
        : await supabase
            .from('posts')
            .insert([postData]);

      if (error) {
        console.error('Database error:', error);
        throw error;
      }

      toast.success(id ? 'Post updated successfully!' : 'Post created successfully!');
      navigate('/admin');
    } catch (error) {
      console.error('Error saving post:', error);
      toast.error('Error saving post: ' + (error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  const isNewPost = window.location.pathname === '/admin/new';

  // Show form for both new post and edit post
  if (isNewPost || id) {
    return (
      <div className="min-h-screen bg-white">
        <div className="relative pt-24">
          <div 
            className="absolute inset-0 bg-cover bg-center h-[300px]"
            style={{
              backgroundImage: "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&w=1920&q=75&fm=webp')",
            }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-white/20 via-white/25 to-white/70"></div>
          </div>
          
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 drop-shadow">
                {isNewPost ? 'Create New Post' : 'Edit Post'}
              </h1>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-white/80 backdrop-blur rounded-lg p-8 shadow-sm">
            <div className="sticky top-[70px] bg-white z-10 py-4 border-b mb-8">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-semibold text-gray-900">
                  {isNewPost ? 'New Post Details' : 'Edit Post Details'}
                </h2>
                <button
                  onClick={() => navigate('/admin')}
                  className="text-brand-teal hover:text-brand-teal-dark transition-colors px-4 py-2 rounded-lg border border-brand-teal hover:bg-brand-teal/5"
                >
                  Back to Posts
                </button>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-8">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title *
                </label>
                <input
                  type="text"
                  value={post.title}
                  onChange={(e) => {
                    const title = e.target.value;
                    const autoSlug = (title || '')
                      .toLowerCase()
                      .replace(/[^a-z0-9]+/g, '-')
                      .replace(/(^-|-$)/g, '');
                    setPost({ ...post, title, slug: autoSlug });
                  }}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-teal focus:border-brand-teal"
                  placeholder="Enter post title"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Slug *
                </label>
                <div className="flex gap-2 items-center">
                  <input
                    type="text"
                    value={post.slug}
                    onChange={(e) => setPost({ ...post, slug: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-teal focus:border-brand-teal"
                    placeholder="post-url-slug"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const autoSlug = (post.title || '')
                        .toLowerCase()
                        .replace(/[^a-z0-9]+/g, '-')
                        .replace(/(^-|-$)/g, '');
                      setPost({ ...post, slug: autoSlug });
                    }}
                    className="px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
                    title="Generate slug from title"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  URL-friendly version of the title. Auto-generated but can be edited.
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description (for SEO)
                </label>
                <textarea
                  value={post.description}
                  onChange={(e) => setPost({ ...post, description: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-teal focus:border-brand-teal"
                  rows={3}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Keywords (comma-separated)
                </label>
                <input
                  type="text"
                  value={post.keywords}
                  onChange={(e) => setPost({ ...post, keywords: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-teal focus:border-brand-teal"
                  placeholder="anxiety, depression, therapy"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Featured Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="w-full"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="h-48 w-full object-cover rounded-lg"
                    />
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Image Alt Text
                </label>
                <input
                  type="text"
                  value={post.image_alt_text}
                  onChange={(e) => setPost({ ...post, image_alt_text: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-teal focus:border-brand-teal"
                  placeholder="Describe the image for accessibility"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Content *
                </label>
                <div className="prose-lg max-w-none">
                  <EditorContent 
                    editor={editor} 
                    className="min-h-[400px] p-4 focus-within:ring-2 focus-within:ring-brand-teal focus-within:border-brand-teal bg-white rounded-lg shadow-sm overflow-y-auto"
                  />
                  {editor && (
                    <BubbleMenu 
                      editor={editor} 
                      tippyOptions={{ 
                        duration: 100,
                        theme: 'dark',
                        maxWidth: 'none'
                      }}
                      className="flex items-center bg-gray-800 shadow-lg rounded-lg border border-gray-700 p-1.5 gap-1.5 whitespace-nowrap"
                    >
                      <button
                        title="Bold (Ctrl+B)"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleBold().run();
                        }}
                        className={editor.isActive('bold') ? activeButtonStyle : buttonStyle}
                      >
                        Bold
                      </button>
                      <button
                        title="Italic (Ctrl+I)"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleItalic().run();
                        }}
                        className={editor.isActive('italic') ? activeButtonStyle : buttonStyle}
                      >
                        Italic
                      </button>
                      <button
                        title="Strikethrough"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleStrike().run();
                        }}
                        className={editor.isActive('strike') ? activeButtonStyle : buttonStyle}
                      >
                        Strike
                      </button>
                      <div className="w-px h-6 bg-gray-600 mx-1"></div>
                      <button
                        title="Normal paragraph"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().setParagraph().run();
                        }}
                        className={editor.isActive('paragraph') ? activeButtonStyle : buttonStyle}
                      >
                        P
                      </button>
                      <button
                        title="Heading 1"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleHeading({ level: 1 }).run();
                        }}
                        className={editor.isActive('heading', { level: 1 }) ? activeButtonStyle : buttonStyle}
                      >
                        H1
                      </button>
                      <button
                        title="Heading 2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleHeading({ level: 2 }).run();
                        }}
                        className={editor.isActive('heading', { level: 2 }) ? activeButtonStyle : buttonStyle}
                      >
                        H2
                      </button>
                      <button
                        title="Heading 3"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleHeading({ level: 3 }).run();
                        }}
                        className={editor.isActive('heading', { level: 3 }) ? activeButtonStyle : buttonStyle}
                      >
                        H3
                      </button>
                      <div className="w-px h-6 bg-gray-600 mx-1"></div>
                      <button
                        title="Bullet list"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleBulletList().run();
                        }}
                        className={editor.isActive('bulletList') ? activeButtonStyle : buttonStyle}
                      >
                        •
                      </button>
                      <button
                        title="Numbered list"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleOrderedList().run();
                        }}
                        className={editor.isActive('orderedList') ? activeButtonStyle : buttonStyle}
                      >
                        1.
                      </button>
                      <button
                        title="Block quote"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleBlockquote().run();
                        }}
                        className={editor.isActive('blockquote') ? activeButtonStyle : buttonStyle}
                      >
                        "
                      </button>
                      <button
                        title="Code block"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          editor.chain().focus().toggleCodeBlock().run();
                        }}
                        className={editor.isActive('codeBlock') ? activeButtonStyle : buttonStyle}
                      >
                        <code className="text-gray-100">{`{}`}</code>
                      </button>
                    </BubbleMenu>
                  )}
                </div>
                <style dangerouslySetInnerHTML={{ __html: `
                  .ProseMirror {
                    min-height: 400px;
                    padding: 1rem;
                    overflow-y: auto;
                  }
                  
                  .ProseMirror:focus {
                    outline: none;
                  }

                  .ProseMirror > * + * {
                    margin-top: 0.75em;
                  }

                  /* Placeholder (at the top) */
                  .ProseMirror p.is-editor-empty:first-child::before {
                    color: #adb5bd;
                    content: attr(data-placeholder);
                    float: left;
                    height: 0;
                    pointer-events: none;
                  }
                  
                  /* Placeholder (in between) */
                  .ProseMirror p.is-empty::before {
                    color: #adb5bd;
                    content: attr(data-placeholder);
                    float: left;
                    height: 0;
                    pointer-events: none;
                  }
                  
                  .ProseMirror h1,
                  .ProseMirror h2,
                  .ProseMirror h3,
                  .ProseMirror h4,
                  .ProseMirror h5,
                  .ProseMirror h6 {
                    line-height: 1.1;
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                  }

                  .ProseMirror h1 {
                    font-size: 2.5rem;
                  }

                  .ProseMirror h2 {
                    font-size: 2rem;
                  }

                  .ProseMirror h3 {
                    font-size: 1.75rem;
                  }

                  .ProseMirror code {
                    background-color: rgba(97, 97, 97, 0.1);
                    color: #616161;
                    padding: 0.25rem;
                    border-radius: 0.25rem;
                  }

                  .ProseMirror pre {
                    background: #0D0D0D;
                    color: #FFF;
                    padding: 0.75rem 1rem;
                    border-radius: 0.5rem;
                  }

                  .ProseMirror pre code {
                    color: inherit;
                    padding: 0;
                    background: none;
                    font-size: 0.8rem;
                  }

                  .ProseMirror blockquote {
                    padding-left: 1rem;
                    border-left: 3px solid #999;
                    margin-left: 0;
                    margin-right: 0;
                  }

                  .ProseMirror hr {
                    border: none;
                    border-top: 2px solid rgba(13, 13, 13, 0.1);
                    margin: 2rem 0;
                  }
                `}} />
              </div>
              
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-brand-teal rounded focus:ring-brand-teal"
                    checked={isPublished}
                    onChange={(e) => setIsPublished(e.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Publish</span>
                </label>
              </div>

              <div className="flex justify-end space-x-4 pt-6">
                <button
                  type="button"
                  onClick={() => navigate('/admin')}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-teal hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal disabled:opacity-50"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Saving...
                    </span>
                  ) : (
                    isNewPost ? 'Create Post' : 'Update Post'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  // Show posts list
  return (
    <div className="min-h-screen bg-white">
      <div className="relative pt-24">
        <div 
          className="absolute inset-0 bg-cover bg-center h-[300px]"
          style={{
            backgroundImage: "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&w=1920&q=75&fm=webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-white/20 via-white/25 to-white/70"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 drop-shadow">
              Blog Administration
            </h1>
            <p className="text-xl font-semibold text-brand-teal mb-4 drop-shadow">
              Manage Your Blog Posts
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white/80 backdrop-blur rounded-lg p-6 shadow-sm">
          <div className="flex justify-between items-center mb-8 sticky top-[70px] bg-white z-10 py-4 border-b">
            <h2 className="text-2xl font-semibold text-gray-900">Blog Posts</h2>
            <button
              onClick={() => navigate('/admin/new')}
              className="bg-brand-teal text-white px-6 py-2 rounded-lg hover:bg-brand-teal-dark transition-colors"
            >
              Create New Post
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-teal"></div>
            </div>
          ) : posts.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500 mb-4">No posts yet.</p>
              <button
                onClick={() => navigate('/admin/new')}
                className="bg-brand-teal text-white px-6 py-2 rounded-lg hover:bg-brand-teal-dark transition-colors"
              >
                Create Your First Post
              </button>
            </div>
          ) : (
            <div className="grid gap-4">
              {posts.map((post) => (
                <div
                  key={post.id}
                  className="border rounded-lg p-6 hover:shadow-md transition-shadow bg-white"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">{post.title}</h3>
                      <p className="text-gray-500 text-sm mt-2">
                        {new Date(post.published_at).toLocaleDateString()}
                      </p>
                    </div>
                    <button
                      onClick={() => navigate(`/admin/${post.id}`)}
                      className="text-brand-teal hover:text-brand-teal-dark transition-colors px-4 py-2 rounded-lg border border-brand-teal hover:bg-brand-teal/5"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}