import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';

export default function ChildCounselling() {
  const benefits = [
    "Safe Space for Expression",
    "Emotional Development",
    "Coping Skills",
    "Behavioral Support",
    "Family Communication",
    "Academic Success"
  ];

  return (
    <div className="pt-16">
      <Meta />
      <div className="bg-white">
        <InnerHeader
          title="Child Counselling"
          subtitle="Nurturing emotional well-being in children through specialized counselling"
          backgroundImage="/images/services.png"
        />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <section className="mb-20 prose prose-lg max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-8">Supporting Your Child's Journey</h2>
            <p className="text-xl leading-relaxed">
              Our child counselling services provide a safe, nurturing environment where children can express themselves, 
              develop emotional awareness, and learn valuable coping skills. We work closely with both children and parents 
              to foster healthy development and resilience.
            </p>
          </section>

          {/* Benefits */}
          <section className="mb-20 bg-white py-16 rounded-2xl shadow-lg">
            <div className="max-w-6xl mx-auto px-6">
              <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">How We Help Children Thrive</h2>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                {benefits.map((benefit, index) => (
                  <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-md transition-shadow duration-300">
                    <p className="text-gray-700 text-lg leading-relaxed">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Our Approach */}
          <section className="mb-20">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Child-Centered Approach</h2>
              <p className="text-xl text-gray-600 mb-12">
                We use age-appropriate therapeutic techniques including play therapy, art therapy, and talk therapy 
                to help children express themselves and develop emotional intelligence. Our experienced counsellors 
                create a warm, accepting environment where children feel safe to explore their feelings and experiences.
              </p>
            </div>
          </section>

          {/* Areas We Address */}
          <section className="mb-20 bg-gray-50 py-16 rounded-2xl">
            <div className="max-w-4xl mx-auto text-center px-6">
              <h2 className="text-4xl font-bold text-gray-900 mb-8">Areas We Address</h2>
              <div className="grid gap-6 md:grid-cols-2">
                <div className="bg-white p-6 rounded-xl shadow-sm">
                  <ul className="text-left text-gray-700 space-y-3">
                    <li>• Anxiety and Depression</li>
                    <li>• Behavioral Challenges</li>
                    <li>• Social Skills</li>
                    <li>• School-Related Issues</li>
                  </ul>
                </div>
                <div className="bg-white p-6 rounded-xl shadow-sm">
                  <ul className="text-left text-gray-700 space-y-3">
                    <li>• Family Changes</li>
                    <li>• Trauma and Loss</li>
                    <li>• Self-Esteem</li>
                    <li>• Emotional Regulation</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Call to Action */}
          <section className="text-center mb-16 bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 py-16 px-6 rounded-2xl shadow-lg">
            <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Child Counselling in Surrey, BC
              </h1>
              <p className="text-xl md:text-2xl text-gray-600 max-w-3xl mb-8">
                Support Your Child's Well-being Today
              </p>
              <p className="text-gray-600 mb-8 text-lg max-w-3xl">
                Take the first step in supporting your child's emotional health. Our experienced child counsellors provide a safe, nurturing environment for children to express themselves and develop healthy coping skills.
              </p>
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
