import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

const PinterestIcon = ({ className }: { className?: string }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className}
  >
    <path d="M12 2a10 10 0 0 0-3.16 19.5c-.07-.7-.15-1.77.03-2.54.17-.7 1.1-4.47 1.1-4.47s-.28-.56-.28-1.4c0-1.3.76-2.28 1.7-2.28.8 0 1.18.6 1.18 1.32 0 .8-.5 2.02-.78 3.14-.22.94.47 1.7 1.4 1.7 1.67 0 2.96-1.76 2.96-4.3 0-2.25-1.62-3.82-3.93-3.82-2.67 0-4.24 2-4.24 4.08 0 .8.31 1.68.7 2.15.07.08.08.16.06.25l-.26 1.06c-.04.17-.14.2-.3.12-1.13-.53-1.83-2.2-1.83-3.55 0-2.97 2.16-5.7 6.21-5.7 3.26 0 5.8 2.32 5.8 5.42 0 3.24-2.04 5.85-4.87 5.85-.95 0-1.84-.49-2.15-1.07l-.58 2.22c-.21.81-.78 1.83-1.16 2.45A10 10 0 1 0 12 2z"/>
  </svg>
);

const socialLinks = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/metrocounsellingtherapy/',
    icon: Facebook,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/metrotherapy11/',
    icon: Twitter,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/metrocounselling/',
    icon: Instagram,
  },
  {
    name: 'Pinterest',
    url: 'https://www.pinterest.com/metrocounselling/',
    icon: PinterestIcon,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/metrocounselling/',
    icon: Linkedin,
  },
];

export default function Footer() {
  return (
    <footer className="bg-brand-teal-dark text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Logo and Description Section */}
          <div className="col-span-2 md:col-span-1">
            <div className="flex items-center mb-4">
              <img
                src="https://metrocounselling.ca/wp-content/uploads/2016/10/logo-metrocouns-76px.png"
                alt="Metro Counselling Logo"
                className="h-12 w-auto"
                width={76}
                height={48}
              />
            </div>
            <p className="text-sm mb-4">
              Professional counselling services providing support and guidance for your mental health journey.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <a
                  key={social.name}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-300 hover:text-brand-teal-light transition-colors"
                  aria-label={social.name}
                >
                  <social.icon className="h-5 w-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links Section */}
          <div>
            <h3 className="text-white font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-brand-teal-light transition-colors">Home</Link></li>
              <li><Link to="/blog" className="hover:text-brand-teal-light transition-colors">Blog</Link></li>
              <li><Link to="/meet-our-counsellors" className="hover:text-brand-teal-light transition-colors">About</Link></li>
              <li><Link to="/contact-us" className="hover:text-brand-teal-light transition-colors">Contact</Link></li>
              <li><Link to="/career" className="hover:text-brand-teal-light transition-colors">Careers</Link></li>
            </ul>
          </div>

          {/* Services Section */}
          <div>
            <h3 className="text-white font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              <li><Link to="/services" className="hover:text-brand-teal-light transition-colors">Counselling Services</Link></li>
              <li><Link to="/other-wellness-therapies" className="hover:text-brand-teal-light transition-colors">Counselling Therapies</Link></li>
              <li><Link to="/holy-fire-reiki-therapy-surrey" className="hover:text-brand-teal-light transition-colors">Reiki Massage</Link></li>
              <li><Link to="/icbc-coverage-and-other-coverage" className="hover:text-brand-teal-light transition-colors">ICBC Billing</Link></li>
              <li><Link to="/tele-health-system" className="hover:text-brand-teal-light transition-colors">Telehealth / Online</Link></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h3 className="text-white font-semibold mb-4">Contact</h3>
            <ul className="space-y-2 text-sm">
              <li>201-10366 136A street</li>
              <li>Surrey, BC V3T 1W4</li>
              <li>
                <a 
                  href="mailto:info@metrocounselling.ca"
                  className="hover:text-brand-teal-light transition-colors"
                >
                  info@metrocounselling.ca
                </a>
              </li>
              <li>
                <a 
                  href="tel:604-619-9992"
                  className="hover:text-brand-teal-light transition-colors"
                >
                  (604) 619-9992
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-brand-teal mt-12 pt-8 text-sm text-center">
          <p>&copy; {new Date().getFullYear()} Metro Counselling. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}