import { motion } from 'framer-motion';

const certifications = [
  {
    name: 'BC Association of Clinical Counsellors',
    logo: '/images/certifications/optimized/bcacc-logo.webp',
    url: 'https://bcacc.ca/counsellors/kristina-sohal/',
  },
  {
    name: 'Psychology Today',
    logo: '/images/certifications/optimized/psychology-today-logo.webp',
    url: 'https://www.psychologytoday.com/ca/therapists/kristina-sohal-surrey-bc/328072',
  },
  {
    name: 'Medimap',
    logo: '/images/certifications/optimized/medimap-logo.webp',
    url: 'https://medimap.ca/clinics/therapists/bc/surrey/metro-counselling-therapy',
  },
  {
    name: 'MVIC',
    logo: '/images/certifications/optimized/mvic-logo.webp',
    url: 'https://mvic.ca',
  },
  {
    name: 'Counselling BC',
    logo: '/images/certifications/optimized/counselling-bc-logo.webp',
    url: 'https://counsellingbc.com/listings/ksohal.htm',
  },
  {
    name: 'Theravive',
    logo: '/images/certifications/optimized/theravive-logo.webp',
    url: 'https://www.theravive.com/therapists/kristina-sohal.aspx',
  },
  {
    name: 'Lumino Health',
    logo: '/images/certifications/optimized/lumino-health-logo.webp',
    url: 'https://luminohealth.sunlife.ca/en/health-care-provider-profile/clinical-counsellor/metro-counselling-therapy/kristina-sohal-686773-686772/',
  },
];

export default function Certifications() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
          Professional Memberships & Listings
        </h2>
        
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 items-center justify-items-center">
          {certifications.map((cert) => (
            <motion.a
              key={cert.name}
              href={cert.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 w-full h-24"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <img
                src={cert.logo}
                alt={`${cert.name} Logo`}
                className="h-16 w-auto object-contain filter hover:brightness-110"
                loading="lazy"
                width="400"
                height="311"
              />
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
}
