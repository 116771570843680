import { type SchemaOrg, type SchemaTypes, type PostalAddress, type GeoCoordinates, type OpeningHoursSpecification } from '../types/schema.ts';

interface BaseSchema extends SchemaOrg {
  '@context': 'https://schema.org';
  '@type': string | string[];
  name: string;
  url: string;
  telephone: string;
  email: string;
  description: string;
  address: PostalAddress;
  geo: GeoCoordinates;
  openingHoursSpecification: OpeningHoursSpecification[];
  sameAs: string[];
}

interface ServiceSchema extends SchemaOrg {
  '@context': 'https://schema.org';
  '@type': string;
  name: string;
  description: string;
  provider: { '@id': string };
  availableService: Array<{
    '@context': 'https://schema.org';
    '@type': string;
    name: string;
    description: string;
  }>;
}

const baseSchema: BaseSchema = {
  '@context': 'https://schema.org',
  '@type': ['MedicalBusiness', 'MedicalOrganization'],
  name: 'Metro Counselling & Therapy Services',
  url: 'https://www.metrocounselling.ca',
  telephone: '+1-604-445-6464',
  email: 'info@metrocounselling.ca',
  description: 'Professional counselling and therapy services in Surrey, BC. Specializing in individual, couple, and family therapy.',
  address: {
    '@context': 'https://schema.org',
    '@type': 'PostalAddress',
    streetAddress: '10362 King George Blvd',
    addressLocality: 'Surrey',
    addressRegion: 'BC',
    postalCode: 'V3T 2W5',
    addressCountry: 'CA'
  },
  geo: {
    '@context': 'https://schema.org',
    '@type': 'GeoCoordinates',
    latitude: '49.191869',
    longitude: '-122.846708'
  },
  openingHoursSpecification: [
    {
      '@context': 'https://schema.org',
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '09:00',
      closes: '18:00'
    },
    {
      '@context': 'https://schema.org',
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Saturday'],
      opens: '10:00',
      closes: '16:00'
    }
  ],
  sameAs: [
    'https://www.facebook.com/metrocounselling',
    'https://www.instagram.com/metrocounselling'
  ]
};

const serviceSchema: ServiceSchema = {
  '@context': 'https://schema.org',
  '@type': 'MedicalTherapy',
  name: 'Counselling Services',
  description: 'Professional counselling services including individual therapy, couples counselling, and specialized mental health support.',
  provider: {
    '@id': 'https://www.metrocounselling.ca/#organization'
  },
  availableService: [
    {
      '@context': 'https://schema.org',
      '@type': 'MedicalService',
      name: 'Individual Counselling',
      description: 'One-on-one counselling sessions for personal growth and mental health support.'
    },
    {
      '@context': 'https://schema.org',
      '@type': 'MedicalService',
      name: 'Couples Therapy',
      description: 'Professional marriage and relationship counselling services.'
    },
    {
      '@context': 'https://schema.org',
      '@type': 'MedicalService',
      name: 'Family Therapy',
      description: 'Counselling services for families to improve communication and resolve conflicts.'
    },
    {
      '@context': 'https://schema.org',
      '@type': 'MedicalService',
      name: 'Mental Health Counselling',
      description: 'Specialized therapy for anxiety, depression, and other mental health concerns.'
    }
  ]
};

const schemaConfig: Record<string, SchemaTypes | SchemaTypes[]> = {
  home: [baseSchema, serviceSchema],
  'individual-counselling-therapy-surrey': {
    '@context': 'https://schema.org',
    '@type': 'MedicalTherapy',
    name: 'Individual Counselling',
    description: 'Professional one-on-one counselling tailored to your needs in Surrey, BC.',
    provider: baseSchema,
    medicalSpecialty: ['Psychotherapy', 'CounsellingService'],
    relevantSpecialty: ['Mental Health', 'Personal Development']
  },
  'couples-therapy-marriage-counselling-surrey': {
    '@context': 'https://schema.org',
    '@type': 'MedicalTherapy',
    name: 'Couples Therapy & Marriage Counselling',
    description: 'Expert marriage and relationship counselling services in Surrey, BC.',
    provider: baseSchema,
    medicalSpecialty: ['CounsellingService', 'MarriageCounselling'],
    relevantSpecialty: ['Relationship Counselling', 'Marriage Therapy']
  }
};

export const getSchemaConfig = (page: string): SchemaTypes | SchemaTypes[] => {
  return schemaConfig[page] || baseSchema;
};

export default schemaConfig;

export type EnhancedSchemaTypes = 'Organization' | 'LocalBusiness' | 'ProfessionalService' | 'Person' | 'FAQPage';

export const getEnhancedSchemaConfig = (page: string): SchemaOrg[] => {
  const baseSchema: SchemaOrg[] = [
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@id': 'https://metrocounselling.ca/#organization',
      name: 'Metro Counselling & Therapy Services',
      url: 'https://metrocounselling.ca',
      logo: 'https://metrocounselling.ca/wp-content/uploads/2016/10/logo-metrocouns-76px.png',
      image: 'https://metrocounselling.ca/og-image.jpg',
      description: 'Professional counselling and therapy services in Surrey, BC. We offer individual therapy, couples counselling, and family therapy.',
      address: {
        '@context': 'https://schema.org',
        '@type': 'PostalAddress',
        streetAddress: '10318 Whalley Blvd',
        addressLocality: 'Surrey',
        addressRegion: 'BC',
        postalCode: 'V3T 4H4',
        addressCountry: 'CA'
      },
      geo: {
        '@context': 'https://schema.org',
        '@type': 'GeoCoordinates',
        latitude: '49.191280',
        longitude: '-122.844960'
      },
      telephone: '+1-604-584-0044',
      priceRange: '$$',
      openingHoursSpecification: [
        {
          '@context': 'https://schema.org',
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '09:00',
          closes: '21:00'
        },
        {
          '@context': 'https://schema.org',
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Saturday'],
          opens: '10:00',
          closes: '17:00'
        }
      ]
    }
  ];

  // Add page-specific schema
  switch (page) {
    case 'home':
      return [
        ...baseSchema,
        {
          '@context': 'https://schema.org',
          '@type': 'ProfessionalService',
          '@id': 'https://metrocounselling.ca/#counselling',
          name: 'Counselling Services',
          provider: {
            '@id': 'https://metrocounselling.ca/#organization'
          },
          areaServed: {
            '@type': 'City',
            name: 'Surrey',
            '@id': 'https://www.wikidata.org/wiki/Q36721'
          },
          hasOfferCatalog: {
            '@type': 'OfferCatalog',
            name: 'Counselling Services',
            itemListElement: [
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Individual Counselling'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Couples Counselling'
                }
              },
              {
                '@type': 'Offer',
                itemOffered: {
                  '@type': 'Service',
                  name: 'Family Therapy'
                }
              }
            ]
          }
        }
      ];

    case 'meet-our-counsellors':
      return [
        ...baseSchema,
        {
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@context': 'https://schema.org',
                '@type': 'Person',
                name: 'Kristina Sohal',
                jobTitle: 'Clinical Counsellor',
                description: 'Registered Clinical Counsellor specializing in anxiety, depression, and trauma therapy.',
                url: 'https://metrocounselling.ca/meet-our-counsellors#kristina-sohal'
              }
            },
            // Add other counsellors here
          ]
        }
      ];

    case 'faq':
      return [
        ...baseSchema,
        {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'What types of therapy do you offer?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'We offer individual counselling, couples counselling, family therapy, and specialized treatments for anxiety, depression, and trauma.'
              }
            },
            {
              '@type': 'Question',
              name: 'Do you offer online counselling?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Yes, we offer secure online counselling sessions through our telehealth system.'
              }
            }
            // Add more FAQs here
          ]
        }
      ];

    default:
      return baseSchema;
  }
};
