import { Heart, Brain, Shield, Users, AlertCircle, HelpingHand } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';

export default function PostTraumaticStress() {
  const treatments = [
    {
      icon: Shield,
      title: 'Trauma-Focused Therapy',
      description: 'Evidence-based treatment addressing the root causes of trauma through specialized therapeutic techniques.',
    },
    {
      icon: Brain,
      title: 'EMDR Therapy',
      description: 'Eye Movement Desensitization and Reprocessing to help process traumatic memories and reduce their impact.',
    },
    {
      icon: Users,
      title: 'Grounding Techniques',
      description: 'Practical tools to stay present, manage trauma responses, and regain control during difficult moments.',
    },
    {
      icon: Heart,
      title: 'Mindfulness Practice',
      description: 'Building awareness and resilience through mindfulness to help manage PTSD symptoms effectively.',
    },
  ];

  const symptoms = [
    {
      category: "Intrusive Thoughts",
      items: [
        "Repeated, involuntary memories",
        "Distressing dreams and nightmares",
        "Vivid flashbacks of the traumatic event",
        "Intense emotional distress at reminders"
      ]
    },
    {
      category: "Avoidance Behaviors",
      items: [
        "Avoiding trauma-related thoughts or feelings",
        "Staying away from trauma-related places or activities",
        "Resistance to discussing the traumatic event",
        "Emotional numbness and detachment"
      ]
    },
    {
      category: "Negative Changes",
      items: [
        "Distorted beliefs about self or others",
        "Persistent negative emotions",
        "Diminished interest in activities",
        "Feeling isolated from others"
      ]
    },
    {
      category: "Arousal & Reactivity",
      items: [
        "Irritability and angry outbursts",
        "Reckless or self-destructive behavior",
        "Hypervigilance and being easily startled",
        "Difficulty concentrating or sleeping"
      ]
    }
  ];

  return (
    <div className="pt-16">
      {/* Hero Section */}
      <div className="relative h-[500px] mb-12">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('/images/optimized/ptsd.webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/70"></div>
        </div>
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            PTSD Counselling in Surrey, BC
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mb-4">
            Professional Support for Post-Traumatic Stress Disorder
          </p>
          <a 
            href="tel:604-619-9992" 
            className="text-2xl md:text-3xl text-white font-medium hover:text-brand-teal transition-colors"
          >
            604-619-9992
          </a>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div>
          {/* Introduction */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Understanding PTSD
            </h2>
            <p className="text-gray-600 mb-6">
              Post-Traumatic Stress Disorder (PTSD) is a complex psychiatric condition that can develop after experiencing or witnessing a traumatic event. While it's natural to feel afraid during and after traumatic situations, PTSD occurs when these reactions persist and interfere with daily life.
            </p>
            <p className="text-gray-600 mb-6">
              The "fight-or-flight" response is our body's natural defense mechanism against danger. However, in PTSD, this response becomes disrupted, leading to neurochemical changes that can make it difficult to process memories and manage anxiety. This can cause individuals to re-experience trauma-related feelings as if the event were occurring in the present.
            </p>
            <div className="bg-gray-50 rounded-lg p-6">
              <p className="text-gray-600 italic">
                "According to the Brain & Behavior Research Foundation, 8 million adults experienced PTSD in the past year, including 5% of children between ages 13-18. Despite these numbers, many still feel stigmatized and avoid seeking the help they need. At Metro Counselling, we're committed to removing these barriers to healing."
              </p>
            </div>
          </div>

          {/* Treatment Approaches */}
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {treatments.map((treatment, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm p-8">
                <treatment.icon className="h-8 w-8 text-brand-teal mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {treatment.title}
                </h3>
                <p className="text-gray-600">{treatment.description}</p>
              </div>
            ))}
          </div>

          {/* Symptoms Grid */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-brand-teal mb-6">
              Recognizing PTSD Symptoms
            </h2>
            <p className="text-gray-600 mb-8">
              PTSD symptoms typically fall into four main categories. The severity and combination of symptoms can vary from person to person:
            </p>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
              {symptoms.map((category, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">
                    {category.category}
                  </h3>
                  <ul className="space-y-3">
                    {category.items.map((item, itemIndex) => (
                      <li key={itemIndex} className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                        <span className="text-gray-600">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Treatment & Support */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-brand-teal mb-6">
              Professional Support & Treatment
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Our Approach to Healing
                </h3>
                <p className="text-gray-600 mb-4">
                  At Metro Counselling, we provide comprehensive PTSD treatment that addresses both the immediate symptoms and underlying trauma. Our experienced therapists will work with you to:
                </p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <HelpingHand className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Develop personalized coping strategies</span>
                  </li>
                  <li className="flex items-start">
                    <HelpingHand className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Process traumatic memories safely</span>
                  </li>
                  <li className="flex items-start">
                    <HelpingHand className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Rebuild a sense of safety and control</span>
                  </li>
                  <li className="flex items-start">
                    <HelpingHand className="h-5 w-5 text-brand-teal mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Address co-occurring conditions</span>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Take the First Step
                </h3>
                <p className="text-gray-600 mb-6">
                  Professional treatment can help end the many risks of untreated PTSD and guide you toward true peace and healing. Our compassionate team is here to support you every step of the way.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Begin Your Healing Journey Today
            </h2>
            <p className="text-gray-600 mb-8 text-lg">
              Take the first step towards healing from trauma. Our experienced counsellors are here to provide the support and guidance you need in a safe, compassionate environment.
            </p>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </div>

          <TherapiesGrid />
        </div>
      </div>
    </div>
  );
}
