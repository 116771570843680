import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { supabase } from '../supabase/supabase';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  content: string;
  description: string | null;
  keywords: string | null;
  image_url: string | null;
  image_alt_text: string | null;
  published_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

const POSTS_PER_PAGE = 9;

export default function Blog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const offset = (currentPage - 1) * POSTS_PER_PAGE;

        const { count: totalCount, error: countError } = await supabase
          .from('posts')
          .select('*', { count: 'exact', head: true })
          .not('published_at', 'is', null); 

        if (countError) throw countError;
        
        if (totalCount !== null) {
          setTotalPages(Math.ceil(totalCount / POSTS_PER_PAGE));
        }

        const { data, error: postsError } = await supabase
          .from('posts')
          .select('*')
          .not('published_at', 'is', null)
          .order('published_at', { ascending: false })
          .range(offset, offset + POSTS_PER_PAGE - 1);

        if (postsError) throw postsError;
        if (data) {
          // Get signed URLs for images
          const postsWithSignedUrls = await Promise.all(
            data.map(async (post) => {
              if (post.image_url) {
                try {
                  const { data: signedUrl } = await supabase
                    .storage
                    .from('blog-images')
                    .createSignedUrl(post.image_url.replace('blog-images/', ''), 3600);
                  
                  return {
                    ...post,
                    image_url: signedUrl?.signedUrl || null
                  };
                } catch (error) {
                  console.error('Error getting signed URL:', error);
                  return post;
                }
              }
              return post;
            })
          );
          setPosts(postsWithSignedUrls);
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load blog posts. Please try again later.');
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-teal"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg text-gray-600">{error}</p>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-white">
        <Meta 
          title="Counselling & Mental Health Blog | Metro Counselling Surrey"
          description="Expert insights on mental health, relationships, and personal growth from Metro Counselling's professional therapists in Surrey, BC. Read our latest articles and tips."
        />
        
        <Helmet>
          <link rel="canonical" href="https://metrocounselling.ca/blog" />
          <meta property="og:title" content="Mental Health & Counselling Blog - Metro Counselling" />
          <meta property="og:description" content="Explore expert insights on mental health, relationships, anxiety, depression, and personal growth. Professional counselling resources and tips." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://metrocounselling.ca/blog" />
        </Helmet>

        <div className="relative pt-24">
          <div 
            className="absolute inset-0 bg-cover bg-center h-[300px]"
            style={{
              backgroundImage: "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&w=1920&q=75&fm=webp')",
            }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-white/20 via-white/25 to-white/70"></div>
          </div>
          
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 drop-shadow">
                Our Blog
              </h1>
              <p className="text-xl text-gray-600 max-w-2xl mx-auto drop-shadow">
                Insights and resources for mental health, personal growth, and well-being
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {posts.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-lg text-gray-600">No blog posts available at the moment.</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {posts.map((post) => (
                <Link 
                  key={post.id}
                  to={`/blog/${post.slug}`}
                  className="group"
                >
                  <div className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300">
                    {post.image_url ? (
                      <div className="aspect-w-16 aspect-h-9">
                        <img
                          src={post.image_url}
                          alt={post.image_alt_text || post.title}
                          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                        />
                      </div>
                    ) : (
                      <div className="aspect-w-16 aspect-h-9 bg-gray-100 flex items-center justify-center">
                        <span className="text-gray-400">No image available</span>
                      </div>
                    )}
                    <div className="p-6">
                      <h2 className="text-xl font-semibold text-gray-900 group-hover:text-brand-teal transition-colors">
                        {post.title}
                      </h2>
                      <p className="mt-2 text-gray-600 line-clamp-3">
                        {post.description || 'No description available'}
                      </p>
                      <div className="mt-4 flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {new Date(post.published_at || post.created_at || Date.now()).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </span>
                        <span className="text-brand-teal group-hover:translate-x-1 transition-transform duration-300">
                          Read more →
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}

          {totalPages > 1 && (
            <div className="mt-12 flex justify-center">
              <nav className="flex items-center space-x-2">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-4 py-2 rounded-md ${
                      currentPage === page
                        ? 'bg-brand-teal text-white'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </nav>
            </div>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
}
