import { Brain, Heart, Shield, Sparkles, Calendar, Star, Users, Flower2 } from 'lucide-react';
import Meta from '../components/Meta';
import TherapiesGrid from '../components/TherapiesGrid';

// Keywords and content for SEO optimization
const pageMetadata = {
  title: "Mental Health Counselling in Surrey, BC | Professional Support | Metro Counselling",
  description: "Expert mental health counselling services in Surrey, BC. Professional support for anxiety, depression, stress management, and overall emotional well-being. Book your consultation today.",
  keywords: "mental health counselling Surrey, mental health therapy BC, anxiety counselling Surrey, depression therapy Surrey, stress management counselling",
};

export default function MentalHealth() {
  const supportAreas = [
    {
      icon: Brain,
      title: 'Emotional Well-being',
      description: 'Develop strategies for managing emotions and building resilience.',
    },
    {
      icon: Heart,
      title: 'Personal Growth',
      description: 'Work towards self-improvement and achieving your full potential.',
    },
    {
      icon: Shield,
      title: 'Stress Management',
      description: 'Learn effective techniques to handle stress and prevent burnout.',
    },
    {
      icon: Users,
      title: 'Relationship Health',
      description: 'Improve connections and communication with others.',
    },
  ];

  const journeySteps = [
    {
      icon: Brain,
      title: 'Assessment',
      description: 'Understanding your unique needs and challenges.',
    },
    {
      icon: Sparkles,
      title: 'Personalized Plan',
      description: 'Creating a tailored approach for your well-being.',
    },
    {
      icon: Star,
      title: 'Growth',
      description: 'Building skills and developing coping strategies.',
    },
    {
      icon: Flower2,
      title: 'Flourishing',
      description: 'Maintaining and celebrating your progress.',
    },
  ];

  return (
    <>
      <Meta 
        title={pageMetadata.title}
        description={pageMetadata.description}
        keywords={pageMetadata.keywords}
      />
      
      <main className="pt-16" role="main">
        {/* Hero Section */}
        <section 
          className="relative min-h-[500px] md:h-[600px] mb-8 md:mb-12" 
          aria-label="Hero section"
        >
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: "url('/images/optimized/flowers2.webp')",
            }}
            role="img"
            aria-label="Decorative flowers representing growth and healing"
          >
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900/40 to-gray-900/80"></div>
          </div>
          
          <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center py-12 md:py-0">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 md:mb-8 leading-tight">
              Your Journey to<br className="hidden sm:block" />Mental Well-being
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white/90 max-w-3xl mb-6 md:mb-8 px-4">
              Professional mental health counselling services in Surrey, BC
            </p>
            <div className="flex flex-col sm:flex-row gap-4 items-center w-full sm:w-auto">
              <a 
                href="tel:604-619-9992" 
                className="w-full sm:w-auto text-xl md:text-2xl text-white bg-brand-teal hover:bg-brand-teal-dark px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all transform hover:scale-105"
                aria-label="Call us at 604-619-9992"
              >
                Call 604-619-9992
              </a>
              <a 
                href="#learn-more" 
                className="w-full sm:w-auto text-lg md:text-xl text-white border-2 border-white hover:bg-white hover:text-gray-900 px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all"
                aria-label="Learn more about our mental health services"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <section id="learn-more" className="text-center mb-12 md:mb-16 scroll-mt-20">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6">
              Comprehensive Mental Health Support
            </h2>
            <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto px-4">
              Professional support for your emotional well-being, helping you develop 
              effective strategies for a balanced and fulfilling life.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-12 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6">
              Understanding Mental Health
            </h2>
            <p className="text-lg text-gray-600">
              Mental health is an essential part of our overall well-being, affecting how we think, 
              feel, and navigate daily life. Our comprehensive approach addresses various aspects 
              of mental health, providing you with the tools and support needed for lasting positive change.
            </p>
          </section>

          <section 
            className="grid md:grid-cols-2 gap-6 md:gap-8 mb-12 md:mb-16"
            aria-label="Support areas"
          >
            {supportAreas.map((area, index) => (
              <article 
                key={index} 
                className="bg-white rounded-xl shadow-sm p-6 md:p-8 hover:shadow-md transition-shadow border border-gray-100"
              >
                <area.icon className="h-8 w-8 md:h-10 md:w-10 text-brand-teal mb-4 md:mb-6" />
                <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-2 md:mb-3">
                  {area.title}
                </h3>
                <p className="text-base md:text-lg text-gray-600">{area.description}</p>
              </article>
            ))}
          </section>

          <section className="bg-gradient-to-br from-gray-50 to-white rounded-2xl shadow-sm p-8 md:p-12 mb-12 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              Your Path to Mental Wellness
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {journeySteps.map((step, index) => (
                <article key={index} className="flex flex-col items-center text-center">
                  <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-brand-teal/10 flex items-center justify-center mb-4">
                    <step.icon className="h-7 w-7 md:h-8 md:w-8 text-brand-teal" />
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2">
                    {step.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600">{step.description}</p>
                </article>
              ))}
            </div>
          </section>

          <div className="grid md:grid-cols-2 gap-8 md:gap-12 mb-12 md:mb-16">
            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Areas We Address
              </h2>
              <div className="space-y-3 text-gray-600">
                {[
                  'Anxiety and Depression',
                  'Stress and Burnout',
                  'Emotional Regulation',
                  'Life Transitions',
                  'Self-esteem and Confidence',
                  'Work-life Balance',
                  'Relationship Challenges',
                  'Personal Development'
                ].map((item, index) => (
                  <p key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>
                    {item}
                  </p>
                ))}
              </div>
            </section>

            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Our Therapeutic Approach
              </h2>
              <p className="text-gray-600 mb-4">
                We combine evidence-based therapies with a compassionate, person-centered approach:
              </p>
              <div className="space-y-3 text-gray-600">
                {[
                  'Cognitive Behavioral Therapy (CBT)',
                  'Mindfulness-Based Techniques',
                  'Solution-Focused Therapy',
                  'Emotional Processing',
                  'Stress Management Strategies',
                  'Interpersonal Skills Development'
                ].map((item, index) => (
                  <p key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>
                    {item}
                  </p>
                ))}
              </div>
            </section>
          </div>

          <section className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 md:p-12 mb-12 md:mb-16">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-6">
                Begin Your Journey Today
              </h2>
              <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8">
                Take the first step towards better mental health. Our experienced counsellors 
                provide a safe, supportive space for you to explore your challenges and find your path forward.
              </p>
              <div className="flex flex-col items-center">
                <p className="text-lg md:text-xl font-medium text-gray-900 mb-4">
                  Schedule Your Consultation
                </p>
                <a 
                  href="tel:604-619-9992" 
                  className="inline-flex items-center gap-2 bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all transform hover:scale-105 w-full sm:w-auto justify-center"
                  aria-label="Call 604-619-9992 to schedule your consultation"
                >
                  <Calendar className="h-5 w-5 md:h-6 md:w-6" />
                  <span className="text-lg md:text-xl">604-619-9992</span>
                </a>
              </div>
            </div>
          </section>

          <section aria-label="Additional therapy services">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              Explore Our Specialized Services
            </h2>
            <TherapiesGrid />
          </section>
        </div>
      </main>
    </>
  );
}
