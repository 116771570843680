import ReactMarkdown from 'react-markdown';

interface CounsellorProps {
  name: string;
  image: string;
  title?: string;
  description: string;
  languages?: string[];
}

export default function CounsellorCard({ name, image, title, description, languages }: CounsellorProps) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-[400px]">
        <img 
          src={image} 
          alt={`${name} - Counsellor at Metro Counselling`}
          className="w-full h-full object-cover object-top"
        />
      </div>
      <div className="p-6">
        <h3 className="text-2xl font-semibold text-gray-900 mb-2">{name}</h3>
        {title && (
          <p className="text-brand-teal font-medium mb-4">{title}</p>
        )}
        {languages && languages.length > 0 && (
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-700">Languages:</p>
            <div className="flex flex-wrap gap-2 mt-1">
              {languages.map((language) => (
                <span 
                  key={language}
                  className="px-2 py-1 text-sm bg-gray-100 text-gray-700 rounded-full"
                >
                  {language}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className="mt-4 prose prose-sm max-w-none prose-headings:text-brand-teal prose-headings:mt-6 prose-headings:mb-2 prose-p:mt-0 prose-ul:mt-1 prose-ul:mb-4 prose-li:mt-0 prose-li:mb-0">
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}
