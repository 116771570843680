import { Mail, Phone, Star, Clock, MapPin } from 'lucide-react';
import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from '../components/ServicesContent';

interface ScheduleItemProps {
  day: string;
  hours: string;
}

function ScheduleItem({ day, hours }: ScheduleItemProps) {
  return (
    <div className="flex justify-between items-center py-2">
      <span className="font-medium">{day}:</span>
      <span className="text-gray-600">{hours}</span>
    </div>
  );
}

interface ReikiTestimonialProps {
  name: string;
  date: string;
  text: string;
  stars: number;
  googleLink: string;
}

function ReikiTestimonial({ name, date, text, stars, googleLink }: ReikiTestimonialProps) {
  // Function to bold "Beth" in the text
  const formatText = (text: string) => {
    return text.replace(/Beth/g, '<strong>Beth</strong>');
  };

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center mb-2">
        {[...Array(stars)].map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
        ))}
      </div>
      <p className="text-gray-700 text-lg leading-relaxed mb-6" 
         dangerouslySetInnerHTML={{ __html: formatText(text) }}>
      </p>
      <div className="border-t pt-4">
        <p className="text-brand-teal font-semibold text-lg">{name}</p>
        <p className="text-gray-500 text-sm mb-2">{date}</p>
        <a href={googleLink} target="_blank" rel="noopener noreferrer" 
           className="inline-flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium">
          Read on Google
          <svg className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20">
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
          </svg>
        </a>
      </div>
    </div>
  );
}

function Testimonials({ testimonials }: { testimonials: ReikiTestimonialProps[] }) {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {testimonials.map((testimonial, index) => (
        <ReikiTestimonial
          key={index}
          name={testimonial.name}
          date={testimonial.date}
          text={testimonial.text}
          stars={testimonial.stars}
          googleLink={testimonial.googleLink}
        />
      ))}
    </div>
  );
}

export default function ReikiTherapy() {
  return (
    <div className="pt-16">
      <Meta />
      <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen">
        <InnerHeader
          title="Holy Fire® Reiki and Massage"
          subtitle="Experience the combined healing power of therapeutic massage and Holy Fire® Reiki for complete mind-body wellness"
          backgroundImage="/images/holyfire.jpg"
        />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Introduction */}
          <section className="mb-20 prose prose-lg max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-8">Holistic Healing Through Touch</h2>
            <p className="text-xl leading-relaxed">
              Are you seeking deep physical relaxation combined with spiritual healing? Welcome to Beth's integrated practice, 
              where therapeutic massage and Holy Fire® Reiki work together to provide powerful, gentle healing for both body and spirit. 
              Our unique approach combines hands-on massage therapy with energy healing to address physical tension, stress, and emotional blockages.
            </p>
          </section>

          {/* Benefits */}
          <section className="mb-20 bg-white py-16 rounded-2xl shadow-lg">
            <div className="max-w-6xl mx-auto px-6">
              <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Benefits of Combined Massage and Reiki</h2>
              <div className="grid gap-8 md:grid-cols-2">
                {[
                  "Enhanced Physical Relief: Therapeutic massage techniques combined with Reiki energy work provide deeper muscle relaxation and pain relief.",
                  "Stress Reduction: The combination of physical touch through massage and energy healing creates a profound state of relaxation.",
                  "Improved Circulation: Massage techniques enhance blood flow while Reiki energy work promotes energy movement throughout the body.",
                  "Emotional Balance: Physical tension release through massage combined with Reiki's emotional healing properties creates complete well-being.",
                  "Faster Recovery: The synergy of massage and Reiki accelerates the body's natural healing processes.",
                  "Mind-Body Integration: Experience complete harmony as massage addresses physical needs while Reiki balances your energy."
                ].map((benefit, index) => (
                  <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-md transition-shadow duration-300">
                    <p className="text-gray-700 text-lg leading-relaxed">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Meet Beth */}
          <section className="mb-20">
            <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Meet Beth – Your Expert in Massage and Energy Healing</h2>
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <div className="grid md:grid-cols-2 gap-12 p-8 lg:p-12 items-center">
                <div className="relative">
                  <div className="absolute inset-0 bg-brand-teal/10 rounded-xl transform -rotate-3"></div>
                  <img
                    src="/images/beth_hq-optimized.webp"
                    alt="Beth - Massage Therapist and Reiki Healer"
                    className="relative rounded-xl shadow-lg w-full transform hover:scale-105 transition-transform duration-500"
                  />
                </div>
                <div className="prose prose-lg">
                  <p className="text-lg leading-relaxed mb-6">
                    Beth is a highly skilled Massage Therapist and Self-Care Coach with extensive experience in 
                    therapeutic massage, Reiki, and holistic healing modalities. Her unique approach combines hands-on 
                    massage techniques with energy work to provide comprehensive healing for body, mind, and spirit.
                  </p>
                  <p className="text-lg leading-relaxed">
                    With years of experience in medical settings, Beth's massage therapy practice integrates traditional 
                    techniques with energy healing to help clients recover from chronic conditions, reduce stress, and 
                    achieve optimal wellness. Her expertise in both physical bodywork and energy healing makes her 
                    treatments uniquely effective.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Testimonials */}
          <section className="mb-20 bg-gradient-to-br from-brand-teal/5 to-transparent py-16 rounded-2xl">
            <div className="max-w-6xl mx-auto px-6">
              <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">What Our Clients Say</h2>
              <Testimonials testimonials={[
                {
                  name: "Anna Rodionova",
                  date: "December 29, 2023",
                  text: "I'm still in awe after my first healing session with Beth. I can't even describe the peacefulness and calm relaxation that still last since our session 10 hours ago. Beth's presence is already soothing but when she does her magic doing the energy work you truly feel like refreshed, cleansed and energized from within. I can't recommend her enough if you need to balance out your energy, to ground yourself, to heal pains and wounds and get a deep relaxation.",
                  stars: 5,
                  googleLink: "https://www.google.com/maps/contrib/101579939114304570330/reviews"
                },
                {
                  name: "Sabrina Dhanji",
                  date: "January 5, 2024",
                  text: "Beth Grant is truly a gifted healer. Throughout different stages of my life, her therapeutic touch and Reiki sessions have provided profound support and healing. She has a natural ability to guide with compassion, empathy, and a deep wisdom that extends beyond her craft. I always leave feeling rejuvenated, balanced, and more connected to myself. I highly recommend her to anyone seeking genuine healing and guidance.",
                  stars: 5,
                  googleLink: "https://www.google.com/maps/contrib/101707254001079806305/reviews"
                },
                {
                  name: "Duane Ross",
                  date: "November 15, 2023",
                  text: "Beth's energy work sessions have helped me process alot. I can't explain what happens in the days following the sessions except to say I feel lighter and different each time. My life and well-being has definitely improved. Thank you Beth",
                  stars: 5,
                  googleLink: "https://www.google.com/maps/contrib/109121346563191176115/reviews?hl=en"
                }
              ]} />
            </div>
          </section>

          {/* Schedule */}
          <section className="mb-20">
            <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Visit Us in Surrey</h2>
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <div className="flex items-center mb-6">
                  <Clock className="w-6 h-6 text-brand-teal mr-3" />
                  <h3 className="text-2xl font-semibold text-gray-900">Available Hours</h3>
                </div>
                <div className="space-y-3">
                  {[
                    { day: "Tuesday", hours: "11 AM – 4 PM" },
                    { day: "Wednesday", hours: "4 PM – 8 PM" },
                    { day: "Thursday", hours: "11 AM – 4 PM" },
                    { day: "Friday", hours: "11 AM – 4 PM" }
                  ].map((item, index) => (
                    <ScheduleItem
                      key={index}
                      day={item.day}
                      hours={item.hours}
                    />
                  ))}
                </div>
              </div>
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <div className="flex items-center mb-6">
                  <MapPin className="w-6 h-6 text-brand-teal mr-3" />
                  <h3 className="text-2xl font-semibold text-gray-900">Location</h3>
                </div>
                <p className="text-gray-600 mb-4">201-10366 136A street, Surrey BC V3T 1W4</p>
                <div className="aspect-video rounded-lg overflow-hidden shadow-md">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.6425132009824!2d-122.84862792361392!3d49.19093197932136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d766c7f986c9%3A0x945d3c6cab8f3b46!2s10366%20136A%20St%2C%20Surrey%2C%20BC%20V3T%201W4!5e0!3m2!1sen!2sca!4v1708847163015!5m2!1sen!2sca"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Office Location Map"
                    className="w-full"
                  ></iframe>
                </div>
                <a 
                  href="https://www.google.com/maps/dir/?api=1&destination=10366+136A+St+Surrey+BC+V3T+1W4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center mt-4 text-brand-teal hover:text-brand-teal-dark"
                >
                  <MapPin className="w-4 h-4 mr-2" />
                  Get Directions
                </a>
              </div>
            </div>
          </section>

          {/* Call to Action */}
          <section className="text-center mb-16 bg-white py-16 px-6 rounded-2xl shadow-lg">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">Begin Your Healing Journey Today</h2>
            <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
              Ready to transform your life with the power of Holy Fire® Reiki and Massage? Book a session with Beth today and 
              embark on a journey towards relaxation, self-realization, stress relief, and overall wellness.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-6">
              <a
                href="tel:604-619-9992"
                className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-xl text-white bg-brand-teal hover:bg-brand-teal-dark transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200"
              >
                <Phone className="w-6 h-6 mr-3" />
                Call 604-619-9992
              </a>
              <a
                href="mailto:contact@metrocounselling.ca"
                className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-xl text-brand-teal bg-white border-2 border-brand-teal hover:bg-brand-teal/5 transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200"
              >
                <Mail className="w-6 h-6 mr-3" />
                Email Us
              </a>
            </div>
          </section>
          <div className="mt-12">
            <ServicesContent />
          </div>
        </div>
      </div>
    </div>
  );
}
