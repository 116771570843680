import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from './App';
import Layout from './components/Layout';
import IndividualCounselling from './pages/IndividualCounselling';
import CouplesTherapy from './pages/CouplesTherapy';
import ReikiTherapy from './pages/ReikiTherapy';
import IcbcBilling from './pages/IcbcBilling';
import Telehealth from './pages/Telehealth';
import OxygenTherapy from './pages/OxygenTherapy';
import IndianHeadMassage from './pages/IndianHeadMassage';
import ChildCounselling from './pages/ChildCounselling';
import MentalHealth from './pages/MentalHealth';
import Anxiety from './pages/therapies/Anxiety';
import Depression from './pages/therapies/Depression';
import PostTraumaticStress from './pages/therapies/PostTraumaticStress';
import AngerManagement from './pages/therapies/AngerManagement';
import WomensIssues from './pages/therapies/WomensIssues';
import GriefTherapy from './pages/therapies/GriefTherapy';
import FamilyTherapy from './pages/therapies/FamilyTherapy';
import LGBTQCounselling from './pages/therapies/LGBTQCounselling';
import Contact from './pages/Contact';
import About from './pages/About';
import Services from './pages/Services';
import Therapies from './pages/Therapies';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';
import Career from './pages/Career';
import CounsellorBio from './components/CounsellorBio';
import Login from './pages/Login';
import BlogAdmin from './supabase/BlogAdmin';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './supabase/auth';
import RedirectWithoutTrailingSlash from './components/RedirectWithoutTrailingSlash';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: '*/',
        element: <RedirectWithoutTrailingSlash />,
      },
      {
        index: true,
        element: <App />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'admin',
        element: (
          <ProtectedRoute>
            <BlogAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/new',
        element: (
          <ProtectedRoute>
            <BlogAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'admin/:id',
        element: (
          <ProtectedRoute>
            <BlogAdmin />
          </ProtectedRoute>
        ),
      },
      {
        path: 'blog',
        element: <Blog />,
      },
      {
        path: 'blog/:slug',
        element: <BlogPost />,
      },
      {
        path: 'meet-our-counsellors',
        element: <About />,
      },
      {
        path: 'career',
        element: <Career />,
      },
      {
        path: 'contact-us',
        element: <Contact />,
      },
      {
        path: 'services',
        element: <Services />,
      },
      {
        path: 'other-wellness-therapies',
        element: <Therapies />,
      },
      {
        path: 'holy-fire-reiki-therapy-surrey',
        element: <ReikiTherapy />,
      },
      {
        path: 'icbc-coverage-and-other-coverage',
        element: <IcbcBilling />,
      },
      {
        path: 'tele-health-system',
        element: <Telehealth />,
      },
      {
        path: 'anxiety-stress-management-counselor-in-surrey',
        element: <Anxiety />,
      },
      {
        path: 'depression-treatment-counsellors-in-surrey',
        element: <Depression />,
      },
      {
        path: 'post-traumatic-stress-disorder-counsellors',
        element: <PostTraumaticStress />,
      },
      {
        path: 'anger-management-counselling-surrey',
        element: <AngerManagement />,
      },
      {
        path: 'womens-issues-counsellors-in-surrey',
        element: <WomensIssues />,
      },
      {
        path: 'grief-counselling-support-surrey',
        element: <GriefTherapy />,
      },
      {
        path: 'family-therapy-counselling-services-in-surrey',
        element: <FamilyTherapy />,
      },
      {
        path: 'lgbtq-counselling',
        element: <LGBTQCounselling />,
      },
      {
        path: 'lgbt-adults',
        element: <Navigate to="/lgbtq-counselling" replace={true} />,
      },
      {
        path: 'lgbt-teens',
        element: <Navigate to="/lgbtq-counselling" replace={true} />,
      },
      {
        path: 'individual-counselling-therapy-surrey',
        element: <IndividualCounselling />,
      },
      {
        path: 'couples-therapy-marriage-counselling-surrey',
        element: <CouplesTherapy />,
      },
      {
        path: 'mental-health-counselling-surrey',
        element: <MentalHealth />,
      },
      {
        path: 'oxygen-therapy-surrey',
        element: <OxygenTherapy />,
      },
      {
        path: 'indian-head-massage-therapy-surrey',
        element: <IndianHeadMassage />,
      },
      {
        path: 'child-counselling-services-surrey',
        element: <ChildCounselling />,
      },
      {
        path: 'individual-counselling',
        element: <IndividualCounselling />,
      },
      {
        path: 'couples-therapy',
        element: <CouplesTherapy />,
      },
      {
        path: 'oxygen-therapy',
        element: <OxygenTherapy />,
      },
      {
        path: 'indian-head-massage',
        element: <IndianHeadMassage />,
      },
      {
        path: 'child-counselling',
        element: <ChildCounselling />,
      },
      {
        path: 'mental-health',
        element: <MentalHealth />,
      },
      {
        path: 'grief-counselling',
        element: <Navigate to="/grief-counselling-support-surrey" replace={true} />,
      },
      {
        path: 'anger-management',
        element: <Navigate to="/anger-management-counselling-surrey" replace={true} />,
      },
      {
        path: 'about-telehealth',
        element: <Navigate to="/tele-health-system" replace={true} />,
      },
      {
        path: 'meeting-in-person-during-the-covid-19',
        element: <Navigate to="/tele-health-system" replace={true} />,
      },
      {
        path: 'counsellor/:counsellorId',
        element: <CounsellorBio />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
