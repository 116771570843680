import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from "../components/ServicesContent";
import { Phone } from "lucide-react";

export default function IndividualCounselling() {
  return (
    <div className="min-h-screen bg-white">
      <Meta />
      <InnerHeader
        title="Individual Counselling in Surrey, BC"
        subtitle="Professional Support for Personal Growth and Well-being"
        backgroundImage="/images/individual-counselling-surrey.jpg"
      />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Main Content */}
          <div className="flex-1">
            <section className="prose prose-lg max-w-4xl mx-auto mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Professional Individual Counselling Services
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                Our individual counselling services in Surrey provide a safe, confidential space for you 
                to explore personal challenges, develop coping strategies, and work towards positive change. 
                Whether you're dealing with anxiety, depression, relationship issues, or seeking personal 
                growth, our experienced counsellors are here to support you.
              </p>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8 mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Our Approach to Individual Therapy
                </h3>
                <p className="text-gray-600">
                  We utilize evidence-based therapeutic approaches tailored to your unique needs and goals. 
                  Our counsellors are trained in various modalities including Cognitive Behavioral Therapy (CBT), 
                  Mindfulness-Based Therapy, and Solution-Focused approaches.
                </p>
              </div>

              <div className="mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  What to Expect in Your Sessions
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Personalized treatment plans based on your specific needs</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">A non-judgmental, supportive environment</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Practical strategies and tools for personal growth</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Regular progress assessment and goal adjustment</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Flexible scheduling options</span>
                  </li>
                </ul>
              </div>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Areas We Address
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Anxiety and Depression</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Stress Management</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Relationship Issues</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Self-esteem and Personal Growth</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Life Transitions</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Trauma Recovery</span>
                  </li>
                </ul>
              </div>
            </section>
          </div>

          {/* Right Column - CTA Card */}
          <div className="md:w-80 lg:w-96">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-24">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Begin Your Journey Today
              </h3>
              <p className="text-gray-600 mb-6">
                Take the first step towards personal growth and well-being. Contact us to schedule your consultation.
              </p>
              <a
                href="tel:604-619-9992"
                className="flex items-center justify-center gap-2 w-full bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-6 py-3 rounded-lg transition-colors"
              >
                <Phone className="h-5 w-5" />
                <span>604-619-9992</span>
              </a>
              <p className="text-sm text-gray-500 mt-4 text-center">
                Professional counselling services in Surrey, BC
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <ServicesContent />
        </div>
      </div>
    </div>
  );
}
