import { useContext } from 'react'
import type { AuthContextType } from '../auth'
import { AuthContext } from '../auth'

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

// Only export the hook, not the type
export type { AuthContextType }