import { Link } from 'react-router-dom';

interface Counsellor {
  name: string;
  title: string;
  image: string;
  slug: string;
}

const counsellors: Counsellor[] = [
  {
    name: "Kristina Sohal",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Kristina_Sohal-optimized.webp",
    slug: "kristina-sohal"
  },
  {
    name: "Minoo Shayesteh",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Minoo-optimized.webp",
    slug: "minoo-shayesteh"
  },
  {
    name: "Cynthia Csepregi",
    title: "RCC BA in Social Work",
    image: "/images/Cynthia-Csepregi-min-optimized.webp",
    slug: "cynthia-csepregi"
  },
  {
    name: "Fatemeh Poorrezvan",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/venus1-optimized.webp",
    slug: "fatemeh-poorrezvan"
  },
  {
    name: "Rafael Tejada",
    title: "RCC Registered Clinical Counsellor",
    image: "/images/rafeal-optimized.webp",
    slug: "rafael-tejada"
  },
  {
    name: "Beth Mares",
    title: "Self-Care Coach & Therapeutic Touch Practitioner",
    image: "/images/beth_hq-optimized.webp",
    slug: "beth-mares"
  }
];

const MeetTheCounsellors = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Meet our Counsellors
          </h2>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {counsellors.map((counsellor) => (
              <Link 
                key={counsellor.name}
                to={`/counsellor/${counsellor.slug}`}
                className="group"
              >
                <div className="relative bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105">
                  <img
                    src={counsellor.image}
                    alt={counsellor.name}
                    className="w-full h-96 object-cover object-top"
                  />
                  <div className="p-6">
                    <h3 className="text-lg font-medium text-gray-900">
                      {counsellor.name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                      {counsellor.title}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTheCounsellors;
