import { Link } from 'react-router-dom';
import { Brain, Heart, Shield, Target } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';

export default function Anxiety() {
  const treatments = [
    {
      icon: Brain,
      title: 'Cognitive Behavioral Therapy',
      description: 'Learn to identify and change thought patterns that lead to anxiety.',
    },
    {
      icon: Heart,
      title: 'Mindfulness Techniques',
      description: 'Develop present-moment awareness to reduce anxiety and stress.',
    },
    {
      icon: Shield,
      title: 'Coping Strategies',
      description: 'Build practical tools to manage anxiety in daily life.',
    },
    {
      icon: Target,
      title: 'Exposure Therapy',
      description: 'Gradually face fears in a safe, controlled environment.',
    },
  ];

  return (
    <div className="pt-16">
      {/* Hero Section */}
      <div className="relative h-[500px] mb-12">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('https://images.unsplash.com/photo-1499209974431-9dddcece7f88?auto=format&fit=crop&w=1920&q=75&fm=webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/70"></div>
        </div>
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Anxiety Counselling in Surrey, BC
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mb-4">
            Professional Anxiety Treatment and Support
          </p>
          <a 
            href="tel:604-619-9992" 
            className="text-2xl md:text-3xl text-white font-medium hover:text-brand-teal transition-colors"
          >
            604-619-9992
          </a>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div>
          <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Understanding Anxiety
            </h2>
            <p className="text-gray-600 mb-6">
              Anxiety is more than just feeling stressed or worried. It's a persistent
              feeling of overwhelming fear or apprehension that can interfere with daily
              life. Our experienced counsellors can help you understand and manage your
              anxiety through evidence-based therapeutic approaches.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 mb-8">
            {treatments.map((treatment, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm p-8">
                <treatment.icon className="h-8 w-8 text-brand-teal mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {treatment.title}
                </h3>
                <p className="text-gray-600">{treatment.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8 mb-8">
            <h2 className="text-2xl font-semibold text-brand-teal mb-6">
              Understanding Your Experience
            </h2>
            <p className="text-gray-600 mb-6">
              At Metro Counselling Vancouver, we understand that some level of stress is a natural part of life. 
              However, when you experience disproportionate tension or anxiety, we're here to help you break free 
              from chronic worrying, learn to calm your anxious mind, and rediscover your sense of hope and happiness.
            </p>
            
            <div className="bg-gray-50 rounded-lg p-6 mb-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">You Are Not Alone</h3>
              <p className="text-gray-600 mb-4">
                Did you know that nearly 53% of Canadians deal with anxiety and depression daily? 
                While this challenge is widespread across our country, many still feel isolated and 
                unsure where to turn for help. These feelings can be overwhelming, but they don't 
                have to define your future.
              </p>
              <p className="text-gray-600">
                It's natural to withdraw when anxiety strikes - it's our mind and body's protective 
                response. However, with the right support and tools, you can move beyond these 
                limitations and discover a new sense of freedom.
              </p>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Recognizing Generalized Anxiety
              </h3>
              <p className="text-gray-600 mb-4">
                If you're wondering whether your worries are "normal" or signs of generalized 
                anxiety disorder, consider these key characteristics. Are your thoughts or fears:
              </p>
              <ul className="grid grid-cols-2 gap-4 mb-6">
                <li className="flex items-center text-gray-600">
                  <div className="h-2 w-2 bg-brand-teal rounded-full mr-3"></div>
                  Excessive
                </li>
                <li className="flex items-center text-gray-600">
                  <div className="h-2 w-2 bg-brand-teal rounded-full mr-3"></div>
                  Intrusive
                </li>
                <li className="flex items-center text-gray-600">
                  <div className="h-2 w-2 bg-brand-teal rounded-full mr-3"></div>
                  Persistent
                </li>
                <li className="flex items-center text-gray-600">
                  <div className="h-2 w-2 bg-brand-teal rounded-full mr-3"></div>
                  Disruptive
                </li>
              </ul>
              <p className="text-gray-600">
                While everyone's experience with anxiety is unique, most people encounter a 
                combination of emotional, behavioral, and physical symptoms that may intensify 
                during stressful periods.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8 mb-8">
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Common Behavioral Signs
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Difficulty relaxing or being alone comfortably</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Challenges with attention and focus</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Procrastination due to feeling overwhelmed</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Avoiding anxiety-triggering situations</span>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  Physical Indicators
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Muscle tension and body aches</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Sleep difficulties due to racing thoughts</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Feeling edgy, restless, or jumpy</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-2 w-2 bg-brand-teal rounded-full mr-3 mt-2"></div>
                    <span className="text-gray-600">Digestive issues and physical discomfort</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bg-brand-teal/5 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Your Path to Recovery
              </h3>
              <p className="text-gray-600 mb-4">
                Social support plays a vital role in managing anxiety. Regular face-to-face 
                interaction with someone who cares about you can effectively calm your nervous 
                system and reduce anxiety symptoms.
              </p>
              <p className="text-gray-600">
                Remember, help is available for everyone, regardless of age or the severity of 
                your anxiety. You deserve to thrive and break free from the limitations that 
                anxiety has created in your life.
              </p>
            </div>
          </div>

          <div className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Take the First Step Towards Managing Your Anxiety
            </h2>
            <p className="text-gray-600 mb-8 text-lg">
              Don't let anxiety control your life. Our experienced counsellors are here to help you develop effective coping strategies and find relief from anxiety symptoms. Reach out today to begin your journey towards peace of mind.
            </p>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </div>

          <TherapiesGrid />
        </div>
      </div>
    </div>
  );
}
