interface InnerHeaderProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
}

export default function InnerHeader({ title, subtitle, backgroundImage }: InnerHeaderProps) {
  return (
    <div className="relative bg-gray-900">
      <div className="absolute inset-0">
        <img
          src={backgroundImage}
          alt={title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gray-900/40 mix-blend-multiply" />
      </div>
      <div className="relative max-w-7xl mx-auto py-40 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center text-center">
        <h2 className="text-4xl font-bold text-white mb-4">{title}</h2>
        <p className="text-xl text-white/90 max-w-2xl">
          {subtitle}
        </p>
      </div>
    </div>
  );
}
