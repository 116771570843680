import { Link } from 'react-router-dom';

const therapies = [
  {
    title: 'Anxiety',
    description: 'Expert counselling for anxiety and stress management in Surrey.',
    path: '/anxiety-stress-management-counselor-in-surrey',
    icon: '😰',
  },
  {
    title: 'Depression',
    description: 'Compassionate depression treatment and counselling services.',
    path: '/depression-treatment-counsellors-in-surrey',
    icon: '😔',
  },
  {
    title: 'Post Traumatic Stress',
    description: 'Specialized trauma therapy and post-traumatic stress counselling.',
    path: '/post-traumatic-stress-disorder-counsellors',
    icon: '🌱',
  },
  {
    title: 'Anger Management',
    description: 'Effective strategies for managing anger and emotional regulation.',
    path: '/anger-management-counselling-surrey',
    icon: '😠',
  },
  {
    title: "Women's Issues",
    description: 'Supportive counselling for women-specific challenges and concerns.',
    path: '/womens-issues-counsellors-in-surrey',
    icon: '👩',
  },
  {
    title: 'Grief Therapy',
    description: 'Compassionate support through loss and bereavement.',
    path: '/grief-counselling-support-surrey',
    icon: '💔',
  },
  {
    title: 'Family Therapy',
    description: 'Strengthening family bonds and improving relationships.',
    path: '/family-therapy-counselling-services-in-surrey',
    icon: '👨‍👩‍👧‍👦',
  },
  {
    title: 'LGBTQ Counselling',
    description: 'Inclusive and affirming counselling for the LGBTQ+ community.',
    path: '/lgbtq-counselling',
    icon: '🌈',
  },
];

export default function TherapiesContent() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
        {therapies.map((therapy) => (
          <Link
            key={therapy.path}
            to={therapy.path}
            className="group relative bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md hover:border-brand-teal/20 transition-all duration-300"
          >
            <div className="h-full">
              <div className="text-4xl mb-4">{therapy.icon}</div>
              <h3 className="text-xl font-semibold text-gray-900 group-hover:text-brand-teal transition-colors">
                {therapy.title}
              </h3>
              <p className="mt-2 text-gray-500">{therapy.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
