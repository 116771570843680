import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from '../components/ServicesContent';

export default function IndianHeadMassage() {
  const benefits = [
    "Reduced Stress and Anxiety",
    "Improved Sleep Quality",
    "Enhanced Circulation",
    "Relief from Headaches",
    "Neck and Shoulder Pain Relief",
    "Mental Clarity and Focus"
  ];

  return (
    <div className="pt-16">
      <Meta />
      <div className="bg-white">
        <InnerHeader
          title="Indian Head Massage"
          subtitle="Ancient techniques for modern stress relief"
          backgroundImage="/images/services.png"
        />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <section className="mb-20 prose prose-lg max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-8">Traditional Indian Head Massage</h2>
            <p className="text-xl leading-relaxed">
              Experience the rejuvenating power of Indian Head Massage, offered by our expert therapist Nazmoon Khan. 
              This ancient technique focuses on relieving stress and tension in the head, neck, and shoulders, 
              promoting deep relaxation and mental clarity.
            </p>
          </section>

          {/* Benefits */}
          <section className="mb-20 bg-white py-16 rounded-2xl shadow-lg">
            <div className="max-w-6xl mx-auto px-6">
              <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Benefits of Indian Head Massage</h2>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                {benefits.map((benefit, index) => (
                  <div key={index} className="bg-gray-50 p-8 rounded-xl hover:shadow-md transition-shadow duration-300">
                    <p className="text-gray-700 text-lg leading-relaxed">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* About the Treatment */}
          <section className="mb-20">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-4xl font-bold text-gray-900 mb-8">About the Treatment</h2>
              <p className="text-xl text-gray-600 mb-12">
                Indian Head Massage is a therapeutic treatment that incorporates massage of the head, neck, 
                shoulders, and upper back. Using traditional techniques passed down through generations, 
                this treatment helps release tension, improve circulation, and promote a sense of well-being.
              </p>
            </div>
          </section>

          {/* Meet Your Therapist */}
          <section className="mb-20">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-4xl font-bold text-gray-900 mb-8">Meet Your Therapist</h2>
              <div className="mb-8">
                <img
                  src="/images/khan_head_massage.jpeg"
                  alt="Nazmoon Khan performing Indian Head Massage"
                  className="rounded-lg mx-auto shadow-lg max-w-2xl w-full"
                />
              </div>
              <p className="text-xl text-gray-600 mb-12">
                Nazmoon Khan brings years of experience and expertise in traditional Indian Head Massage techniques. 
                Her skilled hands and deep understanding of pressure points help provide relief from tension and promote healing.
              </p>
            </div>
          </section>

          {/* Call to Action */}
          <section className="text-center mb-16 bg-white py-16 px-6 rounded-2xl shadow-lg">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">Experience Deep Relaxation</h2>
            <p className="text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
              Ready to experience the calming benefits of Indian Head Massage? Book your session with Nazmoon today 
              and discover a new level of relaxation.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-6">
              <a
                href="tel:604-619-9992"
                className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-teal hover:bg-brand-teal-dark transition-colors duration-300"
              >
                Call to Book: (604) 619-9992
              </a>
            </div>
          </section>

          <div className="mt-12">
            <ServicesContent />
          </div>
        </div>
      </div>
    </div>
  );
}
