import Meta from '../components/Meta';

export default function Career() {
  return (
    <div className="pt-16">
      <Meta
        title="Career Opportunities at Metro Counselling"
        description="Join our team of mental health professionals. Explore counselling and therapy career opportunities at Metro Counselling in Surrey, BC."
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Career Opportunities</h1>
          
          <div className="prose prose-lg max-w-none">
            <p className="text-xl text-gray-600 mb-8">
              Join our team of dedicated mental health professionals at Metro Counselling. We're always looking for passionate individuals who share our commitment to providing exceptional counselling services.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Current Openings</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <div className="px-6 py-8">
                <p className="text-gray-600">
                  We are currently accepting applications for the following positions:
                </p>
                <ul className="mt-4 space-y-4">
                  <li>
                    <div className="border-l-4 border-brand-teal pl-4">
                      <h3 className="text-lg font-medium text-gray-900">Registered Clinical Counsellor</h3>
                      <p className="mt-2 text-gray-600">
                        We're seeking experienced counsellors to join our growing practice. Must be registered with BCACC or CCPA.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="border-l-4 border-brand-teal pl-4">
                      <h3 className="text-lg font-medium text-gray-900">Art Therapist</h3>
                      <p className="mt-2 text-gray-600">
                        Looking for a certified art therapist to expand our therapeutic offerings.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Why Join Metro Counselling?</h2>
            <ul className="mt-6 space-y-4">
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-brand-teal">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span className="ml-3">Supportive and collaborative work environment</span>
              </li>
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-brand-teal">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span className="ml-3">Flexible scheduling options</span>
              </li>
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-brand-teal">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span className="ml-3">Professional development opportunities</span>
              </li>
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-brand-teal">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span className="ml-3">Competitive compensation</span>
              </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">How to Apply</h2>
            <p className="text-gray-600 mb-6">
              To apply for any of our open positions, please send your resume and cover letter to:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-lg font-medium text-gray-900">Email your application to:</p>
              <a href="mailto:careers@metrocounselling.ca" className="text-brand-teal hover:text-brand-teal-dark">
                careers@metrocounselling.ca
              </a>
            </div>

            <div className="mt-12 p-6 bg-brand-teal/10 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Note:</h3>
              <p className="text-gray-600">
                All applicants must be eligible to work in Canada and be registered with their respective professional associations where applicable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
