import { Brain, Heart, UserRound, Car, Wifi, Sparkles, Wind, Stethoscope, Baby } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    title: "Individual Counselling",
    description: "One-on-one counselling to help you navigate life's challenges with professional support and guidance.",
    icon: UserRound,
    path: '/individual-counselling-therapy-surrey',
  },
  {
    title: "Couples Therapy",
    description: "Build stronger relationships through improved communication and understanding with your partner.",
    icon: Heart,
    path: '/couples-therapy-marriage-counselling-surrey',
  },
  {
    title: "Mental Health",
    description: "Comprehensive support for anxiety, depression, and other mental health concerns.",
    icon: Brain,
    path: '/mental-health-counselling-surrey',
  },
  {
    title: "ICBC Counselling",
    description: "Direct billing counselling services for motor vehicle accident recovery, covered by ICBC.",
    icon: Car,
    path: '/icbc-coverage-and-other-coverage',
  },
  {
    title: "Reiki Therapy",
    description: "Energy healing for physical, emotional, and spiritual well-being.",
    icon: Sparkles,
    path: '/holy-fire-reiki-therapy-surrey',
  },
  {
    title: "Telehealth Services",
    description: "Secure online counselling from the comfort of your home.",
    icon: Wifi,
    path: '/tele-health-system/',
  },
  {
    title: "Oxygen Therapy",
    description: "Boost your vitality with innovative oxygen therapy treatments for enhanced wellness and energy.",
    icon: Wind,
    path: '/oxygen-therapy-surrey',
  },
  {
    title: "Indian Head Massage",
    description: "Traditional techniques to relieve stress and tension in the head, neck, and shoulders.",
    icon: Stethoscope,
    path: '/indian-head-massage-therapy-surrey',
  },
  {
    title: "Child Counselling",
    description: "Specialized counselling services to support children's emotional well-being and development.",
    icon: Baby,
    path: '/child-counselling-services-surrey',
  }
];

export default function ServicesContent() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => {
          const Icon = service.icon;
          return (
            <Link
              key={index}
              to={service.path}
              className="group relative bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md hover:border-brand-teal/20 transition-all duration-300"
            >
              <div className="flex items-center gap-4 mb-4">
                <div className="p-2 rounded-lg bg-brand-teal/10 text-brand-teal group-hover:bg-brand-teal group-hover:text-white transition-colors duration-300">
                  <Icon className="w-6 h-6" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  {service.title}
                </h3>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {service.description}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
