import { Heart, Puzzle, MessageCircle, MapPin, Clock, Phone, Calendar, Users } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';
import Meta from '../../components/Meta';

export default function FamilyTherapy() {
  const benefits = [
    {
      icon: Users,
      title: 'Expert Family Counselling',
      description: 'Professional therapy services with experienced counsellors.',
    },
    {
      icon: Heart,
      title: 'Stronger Family Bonds',
      description: 'Build deeper connections with proven counselling approaches.',
    },
    {
      icon: Puzzle,
      title: 'Effective Problem Resolution',
      description: 'Resolve family conflicts with proven therapeutic techniques.',
    },
    {
      icon: MessageCircle,
      title: 'Enhanced Communication',
      description: 'Develop better family communication skills with professional guidance.',
    },
  ];

  const locations = [
    {
      icon: MapPin,
      title: 'Convenient Location',
      description: 'Easily accessible office in Surrey, serving the Lower Mainland.',
    },
    {
      icon: Clock,
      title: 'Flexible Hours',
      description: 'Evening and weekend appointments available for all families.',
    },
    {
      icon: Phone,
      title: 'Virtual Sessions',
      description: 'Online family counselling available throughout British Columbia.',
    },
    {
      icon: Calendar,
      title: 'Quick Response',
      description: 'Prompt appointment scheduling to meet your family\'s needs.',
    },
  ];

  return (
    <div className="pt-16">
      <Meta 
        title="Family Therapy & Counselling in Surrey, BC | Metro Counselling"
        description="Expert family counselling services in Surrey. Professional therapists helping families build stronger relationships throughout Metro Vancouver and the Lower Mainland."
        keywords="family therapy, family counselling, family therapist, relationship counselling, family conflict resolution, Metro Vancouver, Lower Mainland"
      />
      
      {/* Hero Section */}
      <div className="relative h-[500px] mb-12">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('https://images.unsplash.com/photo-1511895426328-dc8714191300?auto=format&fit=crop&w=1920&q=75&fm=webp')",
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/70"></div>
        </div>
        
        <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Family Therapy & Counselling in Surrey, BC
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-3xl mb-4">
            Professional Family Counselling in Surrey and the Lower Mainland
          </p>
          <a 
            href="tel:604-619-9992" 
            className="text-2xl md:text-3xl text-white font-medium hover:text-brand-teal transition-colors"
          >
            604-619-9992
          </a>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div>
          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-3xl font-semibold text-gray-900 mb-6">
              Professional Family Counselling Services
            </h2>
            <p className="text-lg text-gray-600 mb-6 leading-relaxed">
              At Metro Counselling, we provide comprehensive family therapy services.  Our experienced counsellors specialize in helping families overcome challenges, 
              improve communication, and build stronger relationships. Our professional counselling services are here 
              to support your family's growth and healing journey.
            </p>
          </div>

          {/* Benefits Grid */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            Our Family Therapy Services
          </h2>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm p-8 hover:shadow-md transition-shadow">
                <benefit.icon className="h-8 w-8 text-brand-teal mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>

          {/* Locations Grid */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            Serving Metro Vancouver & the Lower Mainland
          </h2>
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {locations.map((location, index) => (
              <div key={index} className="bg-white rounded-xl shadow-sm p-8 hover:shadow-md transition-shadow">
                <location.icon className="h-8 w-8 text-brand-teal mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {location.title}
                </h3>
                <p className="text-gray-600">{location.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Family Counselling Services
            </h2>
            <p className="text-gray-600 mb-6">
              Our counselling services address a wide range of challenges:
            </p>
            <div className="grid md:grid-cols-2 gap-x-12 gap-y-4">
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Communication difficulties in families</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Parenting challenges and support</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Child and adolescent behavioral issues</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Family life transitions and adjustments</span>
                </li>
              </ul>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Blended family integration support</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Family grief and loss counselling</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Mental health support for families</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Family conflict resolution services</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">
              Our Therapeutic Approach
            </h2>
            <p className="text-gray-600 mb-6">
              We utilize evidence-based therapeutic approaches to help families thrive:
            </p>
            <div className="grid md:grid-cols-2 gap-x-12 gap-y-4">
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Modern Systemic Family Therapy</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Structural Family Counselling</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Solution-Focused Family Therapy</span>
                </li>
              </ul>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Narrative Family Counselling</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Attachment-Based Family Therapy</span>
                </li>
                <li className="flex items-start">
                  <span className="text-brand-teal mr-2">•</span>
                  <span>Cognitive Behavioral Family Therapy</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Start Your Family's Healing Journey Today
            </h2>
            <p className="text-gray-600 mb-8 text-lg">
              Take the first step toward building stronger family relationships with Metro Counselling's 
              professional therapy services. Our experienced counsellors are ready to support your 
              family's growth and healing journey. Book your in person consultation today and experience 
              the transformative power of family therapy.
            </p>
            <div className="flex flex-col items-center">
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </div>
        </div>
      </div>
      <TherapiesGrid />
    </div>
  );
}
