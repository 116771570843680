import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from '../components/ServicesContent';

export default function OxygenTherapy() {
  const benefits = [
    "Increased Oxygen Supply",
    "Reduced Inflammation",
    "Enhanced Healing",
    "Improved Blood Flow",
    "Better Mental Clarity",
    "Reduced Fatigue",
    "Enhanced Physical Performance",
    "Better Sleep Quality",
    "Strengthened Immune System"
  ];

  return (
    <div className="min-h-screen bg-white">
      <Meta />
      <div className="bg-white">
        <InnerHeader
          title="Advanced Oxygen Therapy"
          subtitle="Innovative oxygen therapy for enhanced wellness and vitality"
          backgroundImage="/images/services.png"
        />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <section className="prose prose-lg max-w-4xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Experience the Power of Oxygen Therapy
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Our advanced oxygen therapy uses a specialized pressure chamber to increase 
              the amount of oxygen in your blood. This innovative treatment helps reduce swelling, 
              supports natural healing processes, and enhances overall wellness.
            </p>
            <p className="text-lg text-gray-600">
              During your session, you'll breathe in pure oxygen in a comfortable, controlled environment. 
              This increases the oxygen levels in your blood, promoting healing and rejuvenation throughout your body.
            </p>
          </section>

          {/* Key Features */}
          <section className="mb-16">
            <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8">
              <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                Comfortable & Flexible Treatment Options
              </h3>
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <p className="text-gray-600 mb-4">
                    Our unique chamber design allows you to sit comfortably during treatment, 
                    offering the flexibility to adjust between sitting upright and semi-reclining 
                    positions for maximum comfort.
                  </p>
                  <p className="text-gray-600 mb-4">
                    The spacious design accommodates people of all sizes, with an easy-access 
                    entry point and ample room to stretch out during your session.
                  </p>
                  <p className="text-gray-600">
                    Perfect for busy professionals, you can even use your laptop or read 
                    during treatment, making it easy to fit therapy into your schedule.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900 mb-4">Key Benefits:</h4>
                  <ul className="space-y-3">
                    {benefits.map((benefit, index) => (
                      <li key={index} className="flex items-start">
                        <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3 text-gray-600">{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* How It Works */}
          <section className="mb-16">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">How Oxygen Therapy Works</h2>
              <div className="bg-white rounded-xl shadow-lg p-8">
                <p className="text-gray-600 mb-6">
                  Our oxygen therapy sessions take place in a comfortable, specialized chamber 
                  that creates a pressurized environment. This environment allows your body to 
                  absorb more oxygen than would be possible under normal conditions.
                </p>
                <p className="text-gray-600">
                  The increased oxygen levels help:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2 text-gray-600">
                  <li>Support your body's natural healing processes</li>
                  <li>Reduce inflammation and swelling</li>
                  <li>Enhance physical and mental performance</li>
                  <li>Improve overall wellness and vitality</li>
                </ul>
              </div>
            </div>
          </section>

          {/* Call to Action */}
          <section className="text-center mb-16">
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
              Ready to Experience the Benefits?
            </h3>
            <p className="text-gray-600 mb-8">
              Contact us to learn more about our oxygen therapy sessions and how they can benefit your health and wellness journey.
            </p>
            <a
              href="tel:604-619-9992"
              className="inline-flex items-center bg-brand-teal text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-brand-teal-dark transition-colors"
            >
              Call to Book: (604) 619-9992
            </a>
          </section>

          <div className="mt-12">
            <ServicesContent />
          </div>
        </div>
      </div>
    </div>
  );
}
