import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from "../components/ServicesContent";
import { Phone } from "lucide-react";

export default function IcbcBilling() {
  return (
    <div className="min-h-screen bg-white">
      <Meta />
      <InnerHeader
        title="ICBC Counselling Coverage in Surrey, BC"
        subtitle="Professional Support After Motor Vehicle Accidents"
        backgroundImage="/images/icbc-counselling-surrey.jpg"
      />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Main Content */}
          <div className="flex-1">
            <section className="prose prose-lg max-w-4xl mx-auto mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                ICBC Coverage for Counselling Services
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                If you've been involved in a motor vehicle accident in British Columbia, 
                ICBC may cover the cost of your counselling sessions. Our experienced team 
                in Surrey provides professional support to help you recover from the 
                psychological impacts of motor vehicle accidents.
              </p>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8 mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  What ICBC Covers
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Psychological assessment and treatment</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Trauma counselling</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Anxiety and depression support</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Recovery and rehabilitation planning</span>
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Treatment Areas
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Post-accident trauma and PTSD</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Anxiety and panic related to driving</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Depression following accident</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Sleep disturbances</span>
                  </li>
                </ul>
              </div>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Documentation and Support
                </h3>
                <p className="text-gray-600">
                  We handle all necessary documentation and communication with ICBC, allowing 
                  you to focus entirely on your recovery. Our team ensures all required 
                  paperwork is completed accurately and submitted promptly.
                </p>
              </div>
            </section>
          </div>

          {/* Right Column - CTA Card */}
          <div className="md:w-80 lg:w-96">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-24">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Start Your Recovery Today
              </h3>
              <p className="text-gray-600 mb-6">
                Contact us to learn more about ICBC coverage and begin your healing journey.
              </p>
              <a
                href="tel:604-619-9992"
                className="flex items-center justify-center gap-2 w-full bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-6 py-3 rounded-lg transition-colors"
              >
                <Phone className="h-5 w-5" />
                <span>604-619-9992</span>
              </a>
              <p className="text-sm text-gray-500 mt-4 text-center">
                ICBC-covered counselling services in Surrey, BC
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <ServicesContent />
        </div>
      </div>
    </div>
  );
}
