import { Link } from 'react-router-dom';
import Meta from '../components/Meta';
import ServicesContent from '../components/ServicesContent';

export default function Services() {
  return (
    <div className="min-h-screen bg-white">
      <Meta />
      
      {/* SEO Title and Subtitle */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-0 text-center">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
          Professional Counselling Services in Surrey
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Comprehensive mental health and wellness services tailored to your individual needs
        </p>
      </div>

      <ServicesContent />

      {/* View All Therapies Button */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16 mb-16 text-center">
        <Link
          to="/other-wellness-therapies"
          className="inline-flex items-center bg-brand-teal text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-brand-teal-dark transition-colors"
        >
          View Our Therapies
        </Link>
      </div>
    </div>
  );
}
