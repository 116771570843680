import { supabase } from '../supabase/supabase';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

interface BlogPostData {
  title: string;
  content: string;
  published_at: string;
  author?: string;
  image_url?: string | null;
  image_alt_text?: string | null;
}

interface BlogPostProps {
    post: BlogPostData;
}

export default function BlogPost({ post }: BlogPostProps) {
    const [postWithSignedUrl, setPostWithSignedUrl] = useState<BlogPostData | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSignedUrl = async () => {
            if (post && post.image_url) {
                try {
                    const { data: signedUrl } = await supabase
                      .storage
                      .from('blog-images')
                      .createSignedUrl(post.image_url.replace('blog-images/', ''), 3600);
                    
                      setPostWithSignedUrl({
                        ...post,
                        image_url: signedUrl?.signedUrl || null
                      });
                  } catch (error) {
                    console.error('Error getting signed URL:', error);
                    setPostWithSignedUrl(post);
                  }
            } else {
                setPostWithSignedUrl(post);
            }
            setLoading(false);
        };
        fetchSignedUrl();
    }, [post]);

    if (loading) {
        return (
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-teal"></div>
          </div>
        );
      }

  if (!postWithSignedUrl) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-2xl font-bold text-gray-900">Post not found</h1>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{postWithSignedUrl.title} | Metro Counselling</title>
        <meta name="description" content={postWithSignedUrl.content.replace(/<[^>]*>/g, '').substring(0, 160)} />
        {postWithSignedUrl.image_url && (
          <meta property="og:image" content={postWithSignedUrl.image_url} />
        )}
        <meta property="og:title" content={postWithSignedUrl.title} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={postWithSignedUrl.published_at} />
        {postWithSignedUrl.author && (
          <meta property="article:author" content={postWithSignedUrl.author} />
        )}
      </Helmet>
      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12" itemScope itemType="http://schema.org/BlogPosting">
        {postWithSignedUrl.image_url && (
          <div className="aspect-video mb-8 rounded-xl overflow-hidden">
            <img
              src={postWithSignedUrl.image_url}
              alt={postWithSignedUrl.image_alt_text || postWithSignedUrl.title}
              className="w-full h-full object-cover"
              itemProp="image"
            />
          </div>
        )}
        
        <h1 className="text-4xl font-bold text-gray-900 mb-4" itemProp="headline">{postWithSignedUrl.title}</h1>
        
        <div className="flex items-center text-gray-500 mb-8">
          {postWithSignedUrl.author && <span className="mr-4" itemProp="author">By {postWithSignedUrl.author}</span>}
          <time itemProp="datePublished" dateTime={postWithSignedUrl.published_at}>
            {new Date(postWithSignedUrl.published_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </time>
        </div>

        <div 
          className="prose prose-lg max-w-none"
          itemProp="articleBody"
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(postWithSignedUrl.content, { 
              USE_PROFILES: { html: true },
              ADD_TAGS: ['iframe'],
              ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
            }) 
          }}
        />
      </article>
    </>
  );
}
