import { Star, BadgeCheck, ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';

interface Testimonial {
  name: string;
  date: string;
  text: string;
  stars: number;
  googleLink?: string;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
}

export default function Testimonials({ testimonials }: TestimonialsProps) {
  const [showAll, setShowAll] = useState(false);
  const displayedTestimonials = showAll ? testimonials : testimonials.slice(0, 6);

  return (
    <div className="bg-gray-50 pt-8 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">What Our Clients Say</h2>
          <p className="mt-4 text-lg text-gray-600">Real stories from people we've helped</p>
        </div>
        
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {displayedTestimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex items-center mb-4">
                {[...Array(testimonial.stars)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                ))}
              </div>
              <p 
                className="text-gray-600 mb-4"
                dangerouslySetInnerHTML={{ __html: testimonial.text }}
              />
              <div className="mt-auto">
                <div className="flex items-center gap-2">
                  {testimonial.googleLink ? (
                    <>
                      <a 
                        href={testimonial.googleLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-semibold text-gray-900 hover:text-brand-teal transition-colors"
                      >
                        {testimonial.name}
                      </a>
                      <a
                        href={testimonial.googleLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-sm text-green-600 hover:text-green-700 bg-green-50 px-2 py-1 rounded-full"
                      >
                        <BadgeCheck className="w-4 h-4" />
                        Verified Review
                      </a>
                    </>
                  ) : (
                    <span className="font-semibold text-gray-900">{testimonial.name}</span>
                  )}
                </div>
                <p className="text-sm text-gray-500 mt-1">{testimonial.date}</p>
              </div>
            </div>
          ))}
        </div>
        
        {testimonials.length > 6 && (
          <div className="text-center mt-12">
            <button
              onClick={() => setShowAll(!showAll)}
              className="inline-flex items-center gap-2 bg-brand-teal text-white px-6 py-3 rounded-lg hover:bg-brand-teal-dark transition-colors"
            >
              <span>{showAll ? 'Show Less' : 'Show More Reviews'}</span>
              {showAll ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
