import { createContext, useState, useEffect } from 'react'
import { supabase } from './supabase'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { User, AuthError } from '@supabase/supabase-js'

interface AuthContextType {
  user: User | null;
  loading?: boolean;
  signUp: (email: string, password: string) => Promise<{ error?: AuthError }>;
  signIn: (email: string, password: string) => Promise<{ data?: { user: User | null }; error?: AuthError }>;
  signInWithGoogle: (credential: string) => Promise<{ error?: AuthError }>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<{ error?: AuthError }>;
  resetPassword: (newPassword: string) => Promise<{ error?: AuthError }>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  signUp: async () => ({}),
  signIn: async () => ({}),
  signInWithGoogle: async () => ({}),
  signOut: async () => {},
  forgotPassword: async () => ({}),
  resetPassword: async () => ({}),
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    console.log('AuthProvider: Initial render');
    
    // Check active sessions and sets the user
    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log('AuthProvider: Initial session check:', { 
        hasSession: !!session, 
        user: session?.user,
        accessToken: session?.access_token?.slice(0, 10) + '...'
      });
      setUser(session?.user ?? null)
      setLoading(false)
    })

    // Listen for changes on auth state (signed in, signed out, etc.)
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('AuthProvider: Auth state change:', { 
        event, 
        hasSession: !!session,
        user: session?.user,
        accessToken: session?.access_token?.slice(0, 10) + '...'
      });
      
      setUser(session?.user ?? null)
      setLoading(false)

      if (event === 'SIGNED_IN') {
        console.log('AuthProvider: User signed in, navigating to /admin');
        toast.success('Successfully signed in!')
        navigate('/admin')
      }
      if (event === 'SIGNED_OUT') {
        console.log('AuthProvider: User signed out, navigating to /login');
        toast.success('Successfully signed out!')
        navigate('/login')
      }
    })

    return () => {
      console.log('AuthProvider: Cleanup - unsubscribing from auth changes');
      subscription.unsubscribe()
    }
  }, [navigate])

  const signIn = async (email: string, password: string) => {
    try {
      console.log('AuthProvider: Attempting sign in with:', email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      console.log('AuthProvider: Sign in response:', { 
        success: !error,
        hasUser: !!data?.user,
        error,
        session: data?.session ? {
          ...data.session,
          access_token: data.session.access_token.slice(0, 10) + '...'
        } : null
      });

      if (error) {
        console.error('AuthProvider: Sign in error:', error);
        return { error };
      }

      return { data: { user: data.user } };
    } catch (error) {
      console.error('AuthProvider: Unexpected error during sign in:', error);
      return { error: error as AuthError };
    }
  }

  const signUp = async (email: string, password: string) => {
    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
      })
      return { error: error || undefined }
    } catch (error) {
      return { error: error as AuthError }
    }
  }

  const signInWithGoogle = async (credential: string) => {
    try {
      const { error } = await supabase.auth.signInWithIdToken({
        provider: 'google',
        token: credential,
      })
      return { error: error || undefined }
    } catch (error) {
      return { error: error as AuthError }
    }
  }

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut()
      if (error) throw error
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const forgotPassword = async (email: string) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email)
      return { error: error || undefined }
    } catch (error) {
      return { error: error as AuthError }
    }
  }

  const resetPassword = async (newPassword: string) => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      })
      return { error: error || undefined }
    } catch (error) {
      return { error: error as AuthError }
    }
  }

  const value = {
    user,
    loading,
    signUp,
    signIn,
    signInWithGoogle,
    signOut,
    forgotPassword,
    resetPassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider, type AuthContextType }