import { Menu, X } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link
      to={to}
      className={`${
        isActive ? 'text-brand-teal' : 'text-gray-700'
      } hover:text-brand-teal px-3 py-2 text-sm font-medium transition-colors`}
    >
      {children}
    </Link>
  );
};

const MobileNavLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`${
        isActive ? 'text-brand-teal' : 'text-gray-700'
      } hover:text-brand-teal block px-3 py-2 text-base font-medium transition-colors`}
    >
      {children}
    </Link>
  );
};

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full bg-white/95 backdrop-blur-sm z-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link to="/">
              <img 
                src="/images/logo-metrocouns-76px.png"
                alt="Metro Counselling Logo"
                className="h-12 w-auto"
                width={76}
                height={48}
              />
            </Link>
            <span className="ml-2 text-xl font-semibold text-gray-800">Metro Counselling</span>
          </div>
          
          <div className="hidden md:flex space-x-8">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/other-wellness-therapies">Therapies</NavLink>
            <NavLink to="/blog">Blog</NavLink>
            <NavLink to="/meet-our-counsellors">About</NavLink>
            <NavLink to="/contact-us">Contact</NavLink>
          </div>

          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)} 
              className="text-gray-700 hover:text-brand-teal transition-colors"
              aria-label="Toggle menu"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white border-t border-gray-100">
            <MobileNavLink to="/">Home</MobileNavLink>
            <MobileNavLink to="/services">Services</MobileNavLink>
            <MobileNavLink to="/other-wellness-therapies">Therapies</MobileNavLink>
            <MobileNavLink to="/blog">Blog</MobileNavLink>
            <MobileNavLink to="/meet-our-counsellors">About</MobileNavLink>
            <MobileNavLink to="/contact-us">Contact</MobileNavLink>
          </div>
        </div>
      )}
    </nav>
  );
}