import TherapiesGrid from '../components/TherapiesGrid';
import { Outlet, Link } from 'react-router-dom';
import Meta from '../components/Meta';

export default function Therapies() {
  return (
    <div className="min-h-screen bg-white">
      <Meta />
      
      {/* SEO Title and Subtitle */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-0 text-center">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
          Specialized Therapy Services in Surrey
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Expert therapeutic approaches for various mental health and wellness needs
        </p>
      </div>

      <TherapiesGrid />

      {/* View All Services Button */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16 mb-16 text-center">
        <Link
          to="/services"
          className="inline-flex items-center bg-brand-teal text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-brand-teal-dark transition-colors"
        >
          View Our Services
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
