import CounsellorCard from '../components/CounsellorCard';
import Meta from '../components/Meta';

const counsellors = [
  {
    name: "Kristina Sohal",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Kristina_Sohal-optimized.webp",
    languages: ["English", "Punjabi"],
    description: `I'm Kristina, a registered counsellor in Vancouver who helps transform lives through compassionate and non-judgmental therapy.

**My Journey**
My path to counselling began in the finance industry, where I worked for several years. While helping clients with financial stress, I discovered my true passion for supporting people through their challenges. This led me to pursue a career in therapy, earning my Masters of Counselling Psychology from Yorkville University.

**My Approach**
As a member of the British Columbia Association of Counsellors, I take pride in:
• Providing a safe, non-judgmental space for healing
• Taking time to truly listen and understand each client's unique journey
• Offering personalized tools for positive life changes

**Therapeutic Methods**
I employ various evidence-based approaches including:
• Cognitive Behavioural Therapy (CBT)
• Mindful Exercises
• Solution-Focused Therapy
• Person Centered Therapy
• Exposure Therapy
• Empty Chair Techniques`,
  },
  {
    name: "Minoo Shayesteh",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/Minoo-optimized.webp",
    languages: ["English", "Persian", "French (Intermediate)"],
    description: `**Professional Background**
As a clinical counselor and psychologist, I bring extensive experience in helping individuals navigate life's challenges. I am a registered clinical counselor with the British Columbia Association of Clinical Counselors (BCACC), specializing in:
• Concurrent disorders
• Mood disorders
• Trauma healing
• Concussion management

**Therapeutic Approaches**
I utilize evidence-based modalities including:
• Cognitive Behavioral Therapy (CBT)
• Dialectical Behavior Therapy (DBT)
• Acceptance and Commitment Therapy (ACT)
• Solution-Focused Therapy
• Eye Movement Desensitization and Reprocessing (EMDR)

**Areas of Focus**
My practice supports clients with:
• Trauma recovery
• Depression and anxiety
• Substance abuse
• Family dynamics
• Interpersonal relationships
• Stress management

My passion lies in helping individuals heal from traumatic experiences and develop healthy self-perceptions, guiding them toward peace, completeness, and safety.`,
  },
  {
    name: "Cynthia Csepregi",
    title: "RCC, BA in Social Work",
    image: "/images/Cynthia-Csepregi-min-optimized.webp",
    languages: ["English"],
    description: `**Welcome**
I'm Cynthia, and I'm excited to be part of the Metro Counselling team. As a registered Social Worker and Counsellor, I believe strongly in people's capacity for positive change.

**Professional Journey**
My career path includes:
• 14 years in complex mental health settings
• Extensive work in non-profit organizations
• Experience with developmental disabilities programs
• Masters in Counselling from City University of Seattle
• Specialized research in the impact of suicide on families

**Counselling Philosophy**
I bring to my practice:
• A strengths-based approach
• Extensive mental health experience
• Deep empathy and understanding
• Commitment to supporting positive change
• Specialized crisis intervention training

My greatest satisfaction comes from helping clients become architects of change in their own lives. I look forward to being part of your journey toward positive transformation.`,
  },
  {
    name: "Fatemeh Poorrezvan",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/venus1-optimized.webp",
    languages: ["English", "Farsi", "Turkish"],
    description: `**Professional Credentials**
• BCACC Registered Clinical Counselor
• Member of the American Psychological Association (APA)
• Master's degree in Clinical Psychology
• Specialized training in Autism and Neurodiversity
• Expertise in Addiction Management

**Research & Experience**
My research has focused on:
• Hope and expectation in medical settings
• Behavioral discipline and self-efficacy
• Anxiety management in surgical patients
• 13+ years of clinical consultation experience
• Extensive work with diverse patient populations

**Clinical Approach**
I am inspired by:
• Human resilience and healing potential
• Client-centered therapy
• Evidence-based practices
• Collaborative treatment planning
• Multicultural counselling perspectives

Through my hospital experience and work with thousands of patients, I've developed a deep appreciation for the human capacity for growth and healing.`,
  },
  {
    name: "Rafael Tejada",
    title: "RCC, Registered Clinical Counsellor",
    image: "/images/rafeal-optimized.webp",
    languages: ["English", "Spanish"],
    description: `**Areas of Expertise**
I specialize in helping clients navigate:
• Trauma and recovery
• Anxiety and depression
• Personality disorders
• Addiction challenges
• Relationship dynamics
• Family communication

**Therapeutic Focus**
My work centers on:
• Examining attachment difficulties
• Healing relationship injuries
• Building self-understanding
• Developing healthy connections
• Restoring self-esteem and hope

**Counselling Philosophy**
I believe in:
• Facing challenges with kindness
• Building compassionate perspectives
• Embracing our whole selves
• Working collaboratively toward goals
• Creating lasting positive change

Together, we'll work to understand your experiences and build a path toward inner peace and stronger relationships.`,
  },
  {
    name: "Beth Mares",
    title: "Self-Care Coach & Therapeutic Touch Practitioner",
    image: "/images/beth_hq-optimized.webp",
    languages: ["English"],
    description: `Beth is a seasoned Self-Care Coach and Therapeutic Touch Practitioner with extensive experience in Quantum and Ancient Healing Modalities. Specializing in Holistic Bodywork, Massage, and Therapeutic Touch Techniques, Beth's practice blends scientific rigor with spiritual wisdom.

With her compassionate touch, Beth harmonizes individuals' energy fields, aiming to balance and clear their bio-energetic systems. She has spent years working in medical settings, helping diverse clients heal from chronic health conditions. Beth is convinced that energy work and self-care are pivotal for the future of medicine.`,
  },
];

export default function About() {
  return (
    <div className="min-h-screen bg-white">
      <Meta 
        title="About Metro Counselling | Professional Therapists in Surrey, BC"
        description="Meet our experienced team of registered clinical counsellors in Surrey, BC. Providing compassionate mental health care in English and Punjabi. Learn about our approach to therapy."
      />
      
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        <div className="text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Professional Counselling Team in Surrey
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Meet our experienced registered clinical counsellors dedicated to your mental health and well-being
          </p>
        </div>
      </div>

      {/* Team Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3">
          {counsellors.map((counsellor, index) => (
            <article key={index} className="prose prose-lg max-w-none">
              <CounsellorCard {...counsellor} />
            </article>
          ))}
        </div>
      </div>

      {/* Mission Section */}
      <section className="bg-gray-50 py-16 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="prose prose-lg max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
              Our Commitment to Mental Health Care
            </h2>
            <p className="text-gray-600 mb-6">
              At Metro Counselling, we believe in providing accessible, compassionate, and effective mental health care 
              to our community in Surrey and the surrounding areas. Our team of registered clinical counsellors brings 
              diverse expertise and cultural understanding to support your journey towards wellness.
            </p>
            <p className="text-gray-600">
              Whether you're seeking individual counselling, couples therapy, or specialized mental health support, 
              our experienced team is here to help. We offer services in multiple languages and use evidence-based 
              therapeutic approaches tailored to your unique needs.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gradient-to-r from-brand-teal to-brand-teal-dark text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Journey?</h2>
          <p className="text-xl mb-8">
            Take the first step towards positive change with our experienced counselling team.
          </p>
          <a
            href="tel:604-619-9992"
            className="inline-flex items-center bg-white text-brand-teal px-8 py-4 rounded-lg text-lg font-medium hover:bg-gray-50 transition-colors"
          >
            Call Us: 604-619-9992
          </a>
        </div>
      </section>
    </div>
  );
}
