import { Heart, Leaf, Sun, Compass, Calendar, MessageCircle, Sparkles, Clock } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';
import Meta from '../../components/Meta';

// Keywords and content for SEO optimization
const pageMetadata = {
  title: "Grief Counselling in Surrey, BC | Professional Support | Metro Counselling",
  description: "Expert grief counselling services in Surrey, BC. Professional support for loss, bereavement, and healing. In-person & online therapy available. Book your consultation today.",
  keywords: "grief counselling Surrey, grief therapy BC, bereavement counselling, loss counselling, grief support Surrey, professional grief counsellor",
};

export default function GriefTherapy() {
  const supportAreas = [
    {
      icon: Heart,
      title: 'Emotional Support',
      description: 'Process complex emotions in a safe, understanding environment.',
    },
    {
      icon: Leaf,
      title: 'Healing Journey',
      description: 'Find meaning and growth through the grieving process.',
    },
    {
      icon: Sun,
      title: 'Coping Strategies',
      description: 'Develop practical tools for managing grief day by day.',
    },
    {
      icon: Compass,
      title: 'Life Navigation',
      description: 'Learn to navigate life while honoring your loss.',
    },
  ];

  const journeySteps = [
    {
      icon: MessageCircle,
      title: 'Initial Consultation',
      description: 'A gentle first conversation to understand your needs and create a supportive plan.',
    },
    {
      icon: Heart,
      title: 'Safe Space Creation',
      description: 'Establish a trusting environment where all emotions are welcome.',
    },
    {
      icon: Sparkles,
      title: 'Healing Process',
      description: 'Work through grief at your own pace with professional guidance.',
    },
    {
      icon: Clock,
      title: 'Ongoing Support',
      description: 'Continuous care and adjustable strategies as your journey evolves.',
    },
  ];

  return (
    <>
      <Meta 
        title={pageMetadata.title}
        description={pageMetadata.description}
        keywords={pageMetadata.keywords}
      />
      
      <main className="pt-16" role="main">
        {/* Hero Section */}
        <section 
          className="relative min-h-[500px] md:h-[600px] mb-8 md:mb-12" 
          aria-label="Hero section"
        >
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: "url('/images/optimized/flowers2.webp')",
            }}
            role="img"
            aria-label="Peaceful flowers symbolizing healing and renewal"
          >
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900/40 to-gray-900/80"></div>
          </div>
          
          <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center py-12 md:py-0">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 md:mb-8 leading-tight">
              Find Support in Your<br className="hidden sm:block" />Journey Through Grief
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white/90 max-w-3xl mb-6 md:mb-8 px-4">
              Professional, compassionate counselling for grief and loss in Surrey, BC
            </p>
            <div className="flex flex-col sm:flex-row gap-4 items-center w-full sm:w-auto">
              <a 
                href="tel:604-619-9992" 
                className="w-full sm:w-auto text-xl md:text-2xl text-white bg-brand-teal hover:bg-brand-teal-dark px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all transform hover:scale-105"
                aria-label="Call us at 604-619-9992"
              >
                Call 604-619-9992
              </a>
              <a 
                href="#learn-more" 
                className="w-full sm:w-auto text-lg md:text-xl text-white border-2 border-white hover:bg-white hover:text-gray-900 px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all"
                aria-label="Learn more about our grief counselling services"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <section id="learn-more" className="text-center mb-12 md:mb-16 scroll-mt-20">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6">
              Compassionate Support for Your Grief Journey
            </h2>
            <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto px-4">
              Every person's experience with grief is unique. We provide a safe, understanding space 
              where you can process your loss and find your path to healing.
            </p>
          </section>

          <section 
            className="grid md:grid-cols-2 gap-6 md:gap-8 mb-12 md:mb-16"
            aria-label="Support areas"
          >
            {supportAreas.map((area, index) => (
              <article 
                key={index} 
                className="bg-white rounded-xl shadow-sm p-6 md:p-8 hover:shadow-md transition-shadow border border-gray-100"
              >
                <area.icon className="h-8 w-8 md:h-10 md:w-10 text-brand-teal mb-4 md:mb-6" />
                <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-2 md:mb-3">
                  {area.title}
                </h3>
                <p className="text-base md:text-lg text-gray-600">{area.description}</p>
              </article>
            ))}
          </section>

          <section className="bg-gradient-to-br from-gray-50 to-white rounded-2xl shadow-sm p-8 md:p-12 mb-12 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              Your Healing Journey With Us
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {journeySteps.map((step, index) => (
                <article key={index} className="flex flex-col items-center text-center">
                  <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-brand-teal/10 flex items-center justify-center mb-4">
                    <step.icon className="h-7 w-7 md:h-8 md:w-8 text-brand-teal" />
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2">
                    {step.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600">{step.description}</p>
                </article>
              ))}
            </div>
          </section>

          <div className="grid md:grid-cols-2 gap-8 md:gap-12 mb-12 md:mb-16">
            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Types of Grief We Support
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="space-y-3 text-gray-600">
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Loss of a loved one</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Relationship endings</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Career transitions</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Life changes</p>
                </div>
                <div className="space-y-3 text-gray-600">
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Anticipatory grief</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Complicated grief</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Collective loss</p>
                  <p className="flex items-center"><span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>Cultural grief</p>
                </div>
              </div>
            </section>

            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Our Therapeutic Approach
              </h2>
              <ul className="space-y-3 text-gray-600">
                {[
                  'Personalized support for your unique journey',
                  'Evidence-based grief counselling methods',
                  'Integration of mind-body healing techniques',
                  'Cultural sensitivity and awareness',
                  'Flexible scheduling for ongoing support',
                  'Both in-person and online sessions available'
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>
                    {item}
                  </li>
                ))}
              </ul>
            </section>
          </div>

          <section className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 md:p-12 mb-12 md:mb-16">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-6">
                Begin Your Path to Healing
              </h2>
              <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8">
                Take the first step towards healing with professional support. Our experienced counsellors 
                are here to provide the compassionate guidance you need during this time.
              </p>
              <div className="flex flex-col items-center">
                <p className="text-lg md:text-xl font-medium text-gray-900 mb-4">
                  Schedule Your Consultation
                </p>
                <a 
                  href="tel:604-619-9992" 
                  className="inline-flex items-center gap-2 bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all transform hover:scale-105 w-full sm:w-auto justify-center"
                  aria-label="Call 604-619-9992 to schedule your consultation"
                >
                  <Calendar className="h-5 w-5 md:h-6 md:w-6" />
                  <span className="text-lg md:text-xl">604-619-9992</span>
                </a>
              </div>
            </div>
          </section>

          <section aria-label="Additional therapy services">
            <TherapiesGrid />
          </section>
        </div>
      </main>
    </>
  );
}
