import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Meta from '../components/Meta';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { supabase } from '../supabase/supabase';
import BlogPost from '../components/BlogPost';

interface BlogPostData {
  id: string;
  title: string;
  slug: string;
  content: string;
  description: string;
  keywords: string;
  published_at: string;
  image_url: string;
  image_alt_text: string;
  created_at: string;
  updated_at: string;
  author?: string;
}

export default function BlogPostPage() {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPostData | null>(null);
  const [prevPost, setPrevPost] = useState<BlogPostData | null>(null);
  const [nextPost, setNextPost] = useState<BlogPostData | null>(null);
  const [loading, setLoading] = useState(true);
  const canonicalUrl = `https://metrocounselling.ca/blog/${slug}`;

  useEffect(() => {
    const fetchPost = async () => {
      if (!slug) {
        setLoading(false);
        return;
      }

      try {
        // Fetch current post
        const { data: posts, error } = await supabase
          .from('posts')
          .select('*')
          .eq('slug', slug)
          .limit(1);

        if (error) throw error;
        
        if (posts && posts.length > 0) {
          const post = posts[0];
          setPost(post);

          // Fetch previous post
          const { data: prevPosts } = await supabase
            .from('posts')
            .select('*')
            .lt('published_at', post.published_at)
            .order('published_at', { ascending: false })
            .limit(1);

          setPrevPost(prevPosts?.[0] || null);

          // Fetch next post
          const { data: nextPosts } = await supabase
            .from('posts')
            .select('*')
            .gt('published_at', post.published_at)
            .order('published_at', { ascending: true })
            .limit(1);

          setNextPost(nextPosts?.[0] || null);
        } else {
          setPost(null);
          setPrevPost(null);
          setNextPost(null);
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setPost(null);
        setPrevPost(null);
        setNextPost(null);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="space-y-3">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-4">Post Not Found</h1>
        <p className="text-gray-600 mb-8">The blog post you're looking for doesn't exist or has been removed.</p>
        <a href="/blog" className="text-brand-teal hover:text-brand-teal-dark">
          ← Back to Blog
        </a>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <Meta 
        title={`${post.title} | Metro Counselling Surrey Blog`}
        description={post.description}
      />
      
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        {post.image_url && <meta property="og:image" content={post.image_url} />}
        <meta property="article:published_time" content={post.published_at} />
        {post.keywords && <meta name="keywords" content={post.keywords} />}
      </Helmet>

      <div className="pt-16">
        <BlogPost post={post} />
      </div>

      {/* Post Navigation */}
      <nav className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex justify-between items-center my-12 py-8 border-t border-gray-200">
        <div className="flex-1">
          {prevPost && (
            <Link
              to={`/blog/${prevPost.slug}`}
              className="group flex flex-col text-sm"
            >
              <span className="text-gray-500 mb-1">Previous Post</span>
              <span className="text-brand-teal group-hover:text-brand-teal-dark transition-colors">
                {prevPost.title}
              </span>
            </Link>
          )}
        </div>

        <Link
          to="/blog"
          className="mx-4 inline-flex items-center text-brand-teal hover:text-brand-teal-dark transition-colors"
        >
          <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
          All Posts
        </Link>

        <div className="flex-1 text-right">
          {nextPost && (
            <Link
              to={`/blog/${nextPost.slug}`}
              className="group flex flex-col text-sm"
            >
              <span className="text-gray-500 mb-1">Next Post</span>
              <span className="text-brand-teal group-hover:text-brand-teal-dark transition-colors">
                {nextPost.title}
              </span>
            </Link>
          )}
        </div>
      </nav>
    </HelmetProvider>
  );
}
