import InnerHeader from '../components/InnerHeader';
import Meta from '../components/Meta';
import ServicesContent from "../components/ServicesContent";
import { Phone } from "lucide-react";

export default function Telehealth() {
  return (
    <div className="min-h-screen bg-white">
      <Meta />
      <InnerHeader
        title="Online Counselling Services in Surrey, BC"
        subtitle="Professional Support from the Comfort of Your Home"
        backgroundImage="/images/telehealth-counselling-surrey.jpg"
      />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Main Content */}
          <div className="flex-1">
            <section className="prose prose-lg max-w-4xl mx-auto mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Professional Online Counselling Services
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                Our telehealth counselling services provide the same high-quality mental 
                health support you'd receive in person, delivered securely and conveniently 
                online. Whether you're in Surrey or anywhere in British Columbia, we're here 
                to support your mental health journey.
              </p>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8 mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Benefits of Online Counselling
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Convenient access from your home</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Flexible scheduling options</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">No commute or travel time</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Same quality care as in-person sessions</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Secure and confidential platform</span>
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Our Online Counselling Process
                </h3>
                <p className="text-gray-600">
                  We use secure, user-friendly video conferencing technology to provide 
                  a comfortable and confidential counselling experience. Our platform is 
                  easy to use and meets all privacy and security requirements for 
                  healthcare services.
                </p>
              </div>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8 mb-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Services Available Online
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Individual counselling</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Couples therapy</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Anxiety and depression support</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Stress management</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">Trauma counselling</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="h-6 w-6 text-brand-teal flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-600">PTSD treatment</span>
                  </li>
                </ul>
              </div>

              <div className="bg-gradient-to-br from-brand-teal/5 to-brand-teal/10 rounded-2xl p-8">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  Getting Started
                </h3>
                <p className="text-gray-600">
                  Starting online counselling is simple. After scheduling your appointment, 
                  you'll receive detailed instructions and a secure link for your session. 
                  All you need is a reliable internet connection and a private space for 
                  your sessions.
                </p>
              </div>
            </section>
          </div>

          {/* Right Column - CTA Card */}
          <div className="md:w-80 lg:w-96">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-24">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Begin Your Online Sessions Today
              </h3>
              <p className="text-gray-600 mb-6">
                Contact us to learn more about our online counselling services and schedule your first session.
              </p>
              <a
                href="tel:604-619-9992"
                className="flex items-center justify-center gap-2 w-full bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-6 py-3 rounded-lg transition-colors"
              >
                <Phone className="h-5 w-5" />
                <span>604-619-9992</span>
              </a>
              <p className="text-sm text-gray-500 mt-4 text-center">
                Professional online counselling services in Surrey, BC
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12">
          <ServicesContent />
        </div>
      </div>
    </div>
  );
}
