import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getMetaConfig } from '../config/meta.config';
import { getSchemaConfig, type SchemaTypes } from '../config/schema.config';

interface MetaProps {
  title?: string;
  description?: string;
  keywords?: string;
  canonicalUrl?: string;
}

export default function Meta(props: MetaProps = {}) {
  const location = useLocation();
  const path = location.pathname;
  
  // Convert path to config key
  const getConfigKey = (path: string) => {
    if (path === '/') return 'home';
    // Remove leading slash and convert to key format
    const key = path.substring(1).replace(/\//g, '-');
    return key || 'home';
  };

  const configKey = getConfigKey(path);
  const metaConfig = getMetaConfig(configKey);
  const schemaConfig = getSchemaConfig(configKey);

  // Merge props with config
  const title = props.title || metaConfig.title;
  const description = props.description || metaConfig.description;
  const keywords = props.keywords || metaConfig.keywords;
  const canonicalUrl = props.canonicalUrl || metaConfig.canonicalUrl;

  // Base URL for canonical links
  const siteUrl = 'https://www.metrocounselling.ca';
  const fullUrl = `${siteUrl}${canonicalUrl}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={fullUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />

      {/* JSON-LD Schema */}
      {Array.isArray(schemaConfig) ? (
        schemaConfig.map((schema: SchemaTypes, index: number) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(schema)}
          </script>
        ))
      ) : (
        <script type="application/ld+json">
          {JSON.stringify(schemaConfig)}
        </script>
      )}
    </Helmet>
  );
}
