import Hero from './components/Hero';
import ServicesContent from './components/ServicesContent';
import TherapiesContent from './components/TherapiesContent';
import Certifications from './components/Certifications';
import Testimonials from './components/Testimonials';
import MeetTheCounsellors from './components/MeetTheCounsellors';
import Meta from './components/Meta';
import { Toaster } from 'sonner';

const testimonials = [
  {
    name: "Missy Hayes",
    date: "January 5, 2024",
    text: "My son and I have been coming to Metro Counselling for the last 6 mths after a bad car accident. I have been seeing <strong>Kristina</strong> and my son is seeing <strong>Raphael</strong>, I cant say enough how much we have both progressed since then. They are professional, caring and great at presenting ways of coping with the anxieties and trauma we endured. Thank you",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/108054190044733830246/reviews?hl=en"
  },
  {
    name: "Jag G",
    date: "December 15, 2023",
    text: "I have been seeing <strong>Kristina</strong> at Metro counselling and I am very confident in her abilities. She is knowledgeable, professional and a great coach. She knows how to implement the right strategies to help clients who are dealing with anxiety and depression. Having <strong>Kristina</strong> behind me, supporting me has been my biggest blessing. She is one of the most flexible medical professionals I have ever dealt with. She understands what it's like to have a bad day, and what it feels like to not be able to get up out of bed. When I talk to <strong>Kristina</strong> I can be myself and I know there's zero judgement.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/109431313602483023235/reviews?hl=en"
  },
  {
    name: "Anna Rodionova",
    date: "December 29, 2023",
    text: "I'm still in awe after my first healing session with <strong>Beth</strong>. I can't even describe the peacefulness and calm relaxation that still last since our session 10 hours ago. <strong>Beth's</strong> presence is already soothing but when she does her magic doing the energy work you truly feel like refreshed, cleansed and energized from within.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/101579939114304570330/reviews"
  },
  {
    name: "Geraldine & Nathanael Zaharko",
    date: "January 5, 2024",
    text: "Thank you <strong>Cynthia Csepregi</strong>, I had been experiencing really severe anxiety when driving, after accidently rear ending another vehicle last year. Thanks to all the coping tools and reframing skills I am learning through <strong>Cynthia</strong>, I am beginning to gain back the joy of driving again, one step at a time. Well invested time and energy, as what I am learning can be applied in may areas of my life.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/106115748056517948548/reviews"
  },
  {
    name: "Sabrina Dhanji",
    date: "January 5, 2024",
    text: "<strong>Beth Grant</strong> is truly a gifted healer. Throughout different stages of my life, her therapeutic touch and Reiki sessions have provided profound support and healing. She has a natural ability to guide with compassion, empathy, and a deep wisdom that extends beyond her craft. I always leave feeling rejuvenated, balanced, and more connected to myself.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/101707254001079806305/reviews"
  },
  {
    name: "Sunil Shekhar",
    date: "September 11, 2023",
    text: "Thanks to over all team of Metro Counseling speacially <strong>Rafaiel</strong>, who helps me to overcome from anxieties and mental pressure.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/101678099327447765404/reviews?hl=en"
  },
  {
    name: "Angelo Penalver",
    date: "February 25, 2023",
    text: "<strong>Kristina</strong> took the time today to help me when I was having a very bad panic attack at work. In the first 10 minutes of our counciling, she significantly reduced my panic attack intensity using breathing techniques while in a calm, assuring, non judgmental tone. She gave me a plan on how to deal with my anxiety and panic attacks in the future. After a single session with <strong>Kristina</strong>, I instantly noticed a drastic difference in my mood and feeling of well being.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/112740837293715849084/reviews?hl=en"
  },
  {
    name: "Minna Guo",
    date: "January 20, 2023",
    text: "<strong>Kristina</strong> is a great listener and asks all the right questions to help you draw your own conclusions. She provides the right tools to keep you on track. She is clearly well versed and a kind counselor, and I recommend her to anyone who's in need of reflection.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/110255514815657382972/reviews?hl=en"
  },
  {
    name: "Jessica Ong",
    date: "March 13, 2021",
    text: "I have been attending counselling at Metro Counselling Therapy with Counsellor, <strong>Cynthia Csepregi</strong> - she is amazing! Even though we have worked through the original issue for why I started counselling, I still go to her regularly because I really enjoy our sessions together. I look forward to my sessions with her. I have tried counselling in the past and have always been hesitant and inconsistent with same. However, I feel very at ease with <strong>Cynthia</strong> and she meets me at where I am emotionally and mentally during each session.",
    stars: 5,
    googleLink: "https://www.google.com/maps/contrib/100338210692340874073/reviews?hl=en"
  }
];

export default function App() {
  return (
    <>
      <Toaster position="top-right" richColors />
      <div className="bg-white">
        <Meta />
        <Hero />
        {/* SEO Title and Subtitle */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-0 text-center">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Professional Counselling Services in Surrey
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Comprehensive mental health and wellness services tailored to your individual needs
          </p>
        </div>
        <ServicesContent />
        {/* SEO Title and Subtitle */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-0 text-center">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Specialized Therapy Services in Surrey
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Expert Counselling approaches for various mental health issues
          </p>
        </div>
        <TherapiesContent />
        <MeetTheCounsellors />
        <Certifications />
        <Testimonials testimonials={testimonials} />
      </div>
    </>
  );
}