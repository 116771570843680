interface MetaConfig {
  title: string;
  description: string;
  keywords?: string;
  canonicalUrl: string;
}

interface MetaConfigMap {
  [key: string]: MetaConfig;
}

const metaConfig: MetaConfigMap = {
  home: {
    title: "Professional Counselling Services in Surrey, BC",
    description: "Metro Counselling offers professional therapy services including individual counselling, couples therapy, and specialized mental health support in Surrey, BC.",
    keywords: "counselling, therapy, mental health, Surrey BC, couples therapy, individual counselling",
    canonicalUrl: "/"
  },
  'meet-our-counsellors': {
    title: "Meet Our Professional Counsellors in Surrey, BC",
    description: "Get to know our experienced team of counsellors and therapists at Metro Counselling. Providing compassionate mental health care in Surrey, BC.",
    keywords: "counsellors, therapists, Surrey BC, mental health professionals, counselling team, therapy experts",
    canonicalUrl: "/meet-our-counsellors"
  },
  'contact-us': {
    title: "Contact Metro Counselling - Book Your Session in Surrey",
    description: "Get in touch with Metro Counselling in Surrey, BC. Book your counselling session or inquire about our services. We're here to help.",
    keywords: "contact us, book session, counselling appointment, Surrey location, therapy booking",
    canonicalUrl: "/contact-us"
  },
  services: {
    title: "Our Counselling Services - Metro Counselling Surrey",
    description: "Explore our comprehensive counselling and therapy services. From individual to couples counselling, we offer personalized care for your needs.",
    keywords: "counselling services, therapy options, mental health services, Surrey BC",
    canonicalUrl: "/services"
  },
  'other-wellness-therapies': {
    title: "Wellness & Alternative Therapies in Surrey - Metro Counselling",
    description: "Discover our holistic wellness therapies and alternative healing approaches. Comprehensive care for mind and body in Surrey, BC.",
    keywords: "wellness therapy, alternative therapy, holistic healing, Surrey BC, mental wellness",
    canonicalUrl: "/other-wellness-therapies"
  },
  blog: {
    title: "Mental Health & Wellness Blog - Metro Counselling",
    description: "Explore our blog for expert insights on mental health, relationships, and personal growth. Professional advice from Surrey's trusted counsellors.",
    keywords: "mental health blog, wellness articles, therapy insights, counselling tips, Surrey BC",
    canonicalUrl: "/blog"
  },
  career: {
    title: "Career Opportunities at Metro Counselling Surrey",
    description: "Join our team of mental health professionals. Explore counselling and therapy career opportunities at Metro Counselling in Surrey, BC.",
    keywords: "counselling jobs, therapy careers, mental health jobs, Surrey BC, employment opportunities",
    canonicalUrl: "/career"
  },
  'individual-counselling': {
    title: "Individual Counselling & Therapy in Surrey | Metro Counselling",
    description: "Professional individual counselling services in Surrey. Our experienced therapists provide personalized support for your mental health journey.",
    keywords: "individual counselling, personal therapy, one-on-one counselling, Surrey BC",
    canonicalUrl: "/individual-counselling-therapy-surrey"
  },
  'couples-therapy': {
    title: "Couples Therapy & Marriage Counselling in Surrey",
    description: "Expert couples therapy and marriage counselling services in Surrey. Strengthen your relationship with professional guidance.",
    keywords: "couples therapy, marriage counselling, relationship counselling, Surrey BC",
    canonicalUrl: "/couples-therapy-marriage-counselling-surrey"
  },
  'mental-health': {
    title: "Mental Health Counselling Services in Surrey",
    description: "Comprehensive mental health counselling services in Surrey. Professional support for anxiety, depression, and other mental health concerns.",
    keywords: "mental health counselling, therapy services, psychological support, Surrey BC",
    canonicalUrl: "/mental-health-counselling-surrey"
  },
  'reiki-therapy': {
    title: "Holy Fire Reiki Therapy in Surrey | Metro Counselling",
    description: "Experience the healing benefits of Holy Fire Reiki therapy in Surrey. Professional energy healing for physical and emotional well-being.",
    keywords: "reiki therapy, holy fire reiki, energy healing, Surrey BC",
    canonicalUrl: "/holy-fire-reiki-therapy-surrey"
  },
  'oxygen-therapy': {
    title: "Oxygen Therapy Services in Surrey | Metro Counselling",
    description: "Professional oxygen therapy services in Surrey. Experience the benefits of oxygen therapy for wellness and recovery.",
    keywords: "oxygen therapy, oxygen treatment, wellness therapy, Surrey BC",
    canonicalUrl: "/oxygen-therapy-surrey"
  },
  'indian-head-massage': {
    title: "Indian Head Massage Therapy in Surrey",
    description: "Professional Indian head massage therapy services in Surrey. Experience stress relief and relaxation with traditional techniques.",
    keywords: "indian head massage, head massage therapy, relaxation therapy, Surrey BC",
    canonicalUrl: "/indian-head-massage-therapy-surrey"
  },
  'child-counselling': {
    title: "Child Counselling Services in Surrey | Metro Counselling",
    description: "Specialized child counselling services in Surrey. Professional support for children's mental health and development.",
    keywords: "child counselling, youth therapy, children's mental health, Surrey BC",
    canonicalUrl: "/child-counselling-services-surrey"
  },
  'anxiety-therapy': {
    title: "Anxiety & Stress Management Counselling in Surrey",
    description: "Professional anxiety and stress management counselling in Surrey. Find relief and develop coping strategies with expert guidance.",
    keywords: "anxiety counselling, stress management, anxiety therapy, Surrey BC",
    canonicalUrl: "/anxiety-stress-management-counselor-in-surrey"
  },
  'depression-therapy': {
    title: "Depression Treatment & Counselling in Surrey",
    description: "Expert depression treatment and counselling services in Surrey. Find support and healing with professional therapists.",
    keywords: "depression treatment, depression counselling, mood disorders, Surrey BC",
    canonicalUrl: "/depression-treatment-counsellors-in-surrey"
  },
  'ptsd-therapy': {
    title: "PTSD & Trauma Counselling in Surrey",
    description: "Professional PTSD and trauma counselling in Surrey. Find healing and support with experienced trauma therapists.",
    keywords: "PTSD therapy, trauma counselling, trauma recovery, Surrey BC",
    canonicalUrl: "/post-traumatic-stress-disorder-counsellors"
  },
  'anger-management': {
    title: "Anger Management Counselling in Surrey",
    description: "Professional anger management counselling in Surrey. Learn effective strategies for managing anger with expert guidance.",
    keywords: "anger management, anger counselling, emotional control, Surrey BC",
    canonicalUrl: "/anger-management-counselling-surrey"
  },
  'womens-issues': {
    title: "Women's Issues Counselling in Surrey",
    description: "Specialized counselling for women's issues in Surrey. Professional support for women's mental health and well-being.",
    keywords: "women's counselling, women's therapy, women's mental health, Surrey BC",
    canonicalUrl: "/womens-issues-counsellors-in-surrey"
  },
  'grief-counselling': {
    title: "Grief Counselling & Support in Surrey",
    description: "Compassionate grief counselling services in Surrey. Find support and healing in times of loss.",
    keywords: "grief counselling, bereavement support, loss counselling, Surrey BC",
    canonicalUrl: "/grief-counselling-support-surrey"
  },
  'family-therapy': {
    title: "Family Therapy & Counselling in Surrey",
    description: "Professional family therapy services in Surrey. Strengthen family bonds and resolve conflicts with expert guidance.",
    keywords: "family therapy, family counselling, family support, Surrey BC",
    canonicalUrl: "/family-therapy-counselling-services-in-surrey"
  },
  'lgbtq-counselling': {
    title: "LGBTQ+ Counselling Services in Surrey",
    description: "Inclusive LGBTQ+ counselling services in Surrey. Professional support in a safe, affirming environment.",
    keywords: "LGBTQ counselling, queer therapy, gender therapy, Surrey BC",
    canonicalUrl: "/lgbtq-counselling"
  },
  'icbc-coverage-and-other-coverage': {
    title: "ICBC Coverage & Insurance Information - Metro Counselling",
    description: "Learn about ICBC coverage and other insurance options for counselling services at Metro Counselling in Surrey, BC.",
    keywords: "ICBC coverage, insurance coverage, therapy coverage, Surrey BC",
    canonicalUrl: "/icbc-coverage-and-other-coverage"
  },
  'tele-health-system': {
    title: "Online Counselling & Telehealth Services in Surrey",
    description: "Access professional counselling from home with our secure telehealth services. Virtual therapy sessions available in Surrey, BC.",
    keywords: "online counselling, telehealth, virtual therapy, Surrey BC",
    canonicalUrl: "/tele-health-system"
  },
  'counsellor-kristina-sohal': {
    title: "Kristina Sohal - Registered Clinical Counsellor | Metro Counselling Surrey",
    description: "Meet Kristina Sohal, RCC at Metro Counselling. Specializing in compassionate therapy with expertise in CBT, mindfulness, and solution-focused therapy. Fluent in English and Punjabi.",
    keywords: "Kristina Sohal, counsellor Surrey, RCC, CBT therapy, mindfulness, Punjabi counsellor, Metro Counselling",
    canonicalUrl: "/counsellor/kristina-sohal"
  },
  'counsellor-minoo-shayesteh': {
    title: "Minoo Shayesteh - Registered Clinical Counsellor | Metro Counselling Surrey",
    description: "Meet Minoo Shayesteh, RCC at Metro Counselling. Expert in trauma healing, mood disorders, and CBT. Services available in English, Persian, and French.",
    keywords: "Minoo Shayesteh, counsellor Surrey, RCC, trauma therapy, CBT, Persian counsellor, Metro Counselling",
    canonicalUrl: "/counsellor/minoo-shayesteh"
  },
  'counsellor-cynthia-csepregi': {
    title: "Cynthia Csepregi - RCC, Social Worker | Metro Counselling Surrey",
    description: "Meet Cynthia Csepregi, RCC and Social Worker at Metro Counselling. 14+ years experience in mental health, specialized in crisis intervention and positive change.",
    keywords: "Cynthia Csepregi, counsellor Surrey, RCC, social worker, mental health specialist, crisis intervention, Metro Counselling",
    canonicalUrl: "/counsellor/cynthia-csepregi"
  },
  'counsellor-fatemeh-poorrezvan': {
    title: "Fatemeh Poorrezvan - Registered Clinical Counsellor | Metro Counselling Surrey",
    description: "Meet Fatemeh Poorrezvan, RCC at Metro Counselling. Experienced counsellor offering services in English, Farsi, and Turkish.",
    keywords: "Fatemeh Poorrezvan, counsellor Surrey, RCC, Farsi counsellor, Turkish counsellor, Metro Counselling",
    canonicalUrl: "/counsellor/fatemeh-poorrezvan"
  },
  'counsellor-rafael-tejada': {
    title: "Rafael Tejada - Registered Clinical Counsellor | Metro Counselling Surrey",
    description: "Meet Rafael Tejada, RCC at Metro Counselling Surrey. Professional counsellor dedicated to supporting your mental health journey.",
    keywords: "Rafael Tejada, counsellor Surrey, RCC, mental health counselling, Metro Counselling",
    canonicalUrl: "/counsellor/rafael-tejada"
  },
  'counsellor-beth-mares': {
    title: "Beth Mares - Self-Care Coach & Therapeutic Touch Practitioner | Metro Counselling Surrey",
    description: "Meet Beth Mares, Self-Care Coach and Therapeutic Touch Practitioner at Metro Counselling. Specializing in holistic wellness and therapeutic touch.",
    keywords: "Beth Mares, self-care coach, therapeutic touch, holistic wellness, Surrey, Metro Counselling",
    canonicalUrl: "/counsellor/beth-mares"
  },
};

export const getMetaConfig = (page: string): MetaConfig => {
  const config = metaConfig[page];
  if (!config) {
    // Return default meta config if page not found
    return {
      title: "Metro Counselling - Professional Therapy Services in Surrey, BC",
      description: "Professional counselling and therapy services in Surrey, BC. Offering individual, couples, and specialized mental health support.",
      keywords: "counselling, therapy, mental health, Surrey BC",
      canonicalUrl: "/"
    };
  }
  return config;
};

export default metaConfig;
