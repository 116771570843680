import { Heart, Brain, Shield, Users, Calendar, Sparkles, Star, Flower2 } from 'lucide-react';
import TherapiesGrid from '../../components/TherapiesGrid';
import Meta from '../../components/Meta';

// Keywords and content for SEO optimization
const pageMetadata = {
  title: "Women's Counselling in Surrey, BC | Mental Health & Wellness | Metro Counselling",
  description: "Professional women's counselling services in Surrey, BC. Specialized support for personal growth, relationships, life transitions, and emotional well-being. Book your consultation today.",
  keywords: "women's counselling Surrey, women's therapy BC, women's mental health Surrey, women's wellness counselling, feminist therapy Surrey",
};

export default function WomensIssues() {
  const areas = [
    {
      icon: Heart,
      title: 'Personal Growth',
      description: 'Explore self-discovery and build confidence in your journey.',
    },
    {
      icon: Brain,
      title: 'Life Transitions',
      description: 'Navigate career changes, relationships, and major life decisions.',
    },
    {
      icon: Shield,
      title: 'Emotional Well-being',
      description: 'Address anxiety, depression, and stress management.',
    },
    {
      icon: Users,
      title: 'Relationship Support',
      description: 'Build healthy boundaries and improve relationships.',
    },
  ];

  const journeySteps = [
    {
      icon: Heart,
      title: 'Safe Space',
      description: 'A supportive environment for open expression and healing.',
    },
    {
      icon: Sparkles,
      title: 'Personal Growth',
      description: 'Discover your strengths and embrace your authentic self.',
    },
    {
      icon: Star,
      title: 'Empowerment',
      description: 'Build confidence and tools for lasting positive change.',
    },
    {
      icon: Flower2,
      title: 'Flourishing',
      description: 'Thrive in all aspects of your life journey.',
    },
  ];

  return (
    <>
      <Meta 
        title={pageMetadata.title}
        description={pageMetadata.description}
        keywords={pageMetadata.keywords}
      />
      
      <main className="pt-16" role="main">
        {/* Hero Section */}
        <section 
          className="relative min-h-[500px] md:h-[600px] mb-8 md:mb-12" 
          aria-label="Hero section"
        >
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: "url('/images/optimized/two_ladies.webp')",
            }}
            role="img"
            aria-label="Representative image for women's counselling"
          >
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900/40 to-gray-900/80"></div>
          </div>
          
          <div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center py-12 md:py-0">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 md:mb-8 leading-tight">
              Empowering Women Through<br className="hidden sm:block" />Professional Support
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white/90 max-w-3xl mb-6 md:mb-8 px-4">
              Specialized counselling for women's mental health & wellness in Surrey, BC
            </p>
            <div className="flex flex-col sm:flex-row gap-4 items-center w-full sm:w-auto">
              <a 
                href="tel:604-619-9992" 
                className="w-full sm:w-auto text-xl md:text-2xl text-white bg-brand-teal hover:bg-brand-teal-dark px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all transform hover:scale-105"
                aria-label="Call us at 604-619-9992"
              >
                Call 604-619-9992
              </a>
              <a 
                href="#learn-more" 
                className="w-full sm:w-auto text-lg md:text-xl text-white border-2 border-white hover:bg-white hover:text-gray-900 px-6 md:px-8 py-3 md:py-4 rounded-lg transition-all"
                aria-label="Learn more about our women's counselling services"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
          <section id="learn-more" className="text-center mb-12 md:mb-16 scroll-mt-20">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6">
              Specialized Support for Women
            </h2>
            <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto px-4">
              A safe, understanding space to explore your challenges and develop strategies 
              for growth and empowerment.
            </p>
          </section>

          <section className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-12 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6">
              Understanding Women's Unique Challenges
            </h2>
            <p className="text-lg text-gray-600">
              Women face unique challenges in today's world, from societal
              pressures and work-life balance to relationship dynamics and personal
              identity. Our counselling services provide a safe, understanding space
              to explore these challenges and develop strategies for growth and
              empowerment.
            </p>
          </section>

          <section 
            className="grid md:grid-cols-2 gap-6 md:gap-8 mb-12 md:mb-16"
            aria-label="Support areas"
          >
            {areas.map((area, index) => (
              <article 
                key={index} 
                className="bg-white rounded-xl shadow-sm p-6 md:p-8 hover:shadow-md transition-shadow border border-gray-100"
              >
                <area.icon className="h-8 w-8 md:h-10 md:w-10 text-brand-teal mb-4 md:mb-6" />
                <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-2 md:mb-3">
                  {area.title}
                </h3>
                <p className="text-base md:text-lg text-gray-600">{area.description}</p>
              </article>
            ))}
          </section>

          <section className="bg-gradient-to-br from-gray-50 to-white rounded-2xl shadow-sm p-8 md:p-12 mb-12 md:mb-16">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6 md:mb-8 text-center">
              Your Journey to Empowerment
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {journeySteps.map((step, index) => (
                <article key={index} className="flex flex-col items-center text-center">
                  <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-brand-teal/10 flex items-center justify-center mb-4">
                    <step.icon className="h-7 w-7 md:h-8 md:w-8 text-brand-teal" />
                  </div>
                  <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2">
                    {step.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600">{step.description}</p>
                </article>
              ))}
            </div>
          </section>

          <div className="grid md:grid-cols-2 gap-8 md:gap-12 mb-12 md:mb-16">
            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Areas We Address
              </h2>
              <div className="space-y-3 text-gray-600">
                {[
                  'Self-esteem and body image',
                  'Career challenges and work-life balance',
                  'Relationship and family dynamics',
                  'Life transitions and personal growth',
                  'Stress and anxiety management',
                  'Trauma and abuse recovery',
                  'Pregnancy and postpartum support',
                  'Identity and personal empowerment'
                ].map((item, index) => (
                  <p key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>
                    {item}
                  </p>
                ))}
              </div>
            </section>

            <section className="bg-white rounded-xl shadow-sm p-6 md:p-8">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4 md:mb-6">
                Our Therapeutic Approach
              </h2>
              <p className="text-gray-600 mb-4">
                We provide a feminist-informed, trauma-sensitive approach that
                recognizes and honors women's experiences. Our counselling combines:
              </p>
              <div className="space-y-3 text-gray-600">
                {[
                  'Person-centered therapy',
                  'Cognitive Behavioral Therapy (CBT)',
                  'Mindfulness techniques',
                  'Emotional Freedom Techniques (EFT)',
                  'Narrative therapy',
                  'Solution-focused approaches'
                ].map((item, index) => (
                  <p key={index} className="flex items-center">
                    <span className="w-2 h-2 bg-brand-teal rounded-full mr-2"></span>
                    {item}
                  </p>
                ))}
              </div>
            </section>
          </div>

          <section className="bg-gradient-to-br from-brand-teal/10 to-brand-teal/5 rounded-xl p-8 mb-12">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Women's Counselling in Surrey, BC
              </h2>
              <p className="text-xl md:text-2xl text-gray-600 mb-8">
                Begin Your Journey to Wellness Today
              </p>
              <p className="text-gray-600 mb-8 text-lg">
                Take the first step towards personal growth and emotional well-being. Our experienced counsellors provide a safe, supportive environment for women to explore their challenges and discover their strength.
              </p>
              <p className="text-xl font-semibold text-gray-900 mb-4">
                Call us to book your consultation
              </p>
              <a 
                href="tel:604-619-9992" 
                className="inline-block bg-brand-teal hover:bg-brand-teal-dark text-white font-medium px-8 py-4 rounded-lg transition-colors text-center text-xl"
              >
                604-619-9992
              </a>
            </div>
          </section>

          <section aria-label="Additional therapy services">
            <TherapiesGrid />
          </section>
        </div>
      </main>
    </>
  );
}
