import { Link } from 'react-router-dom';
import Meta from '../components/Meta';

export default function NotFound() {
  return (
    <div className="min-h-screen bg-gray-50 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <Meta 
        title="Page Not Found - Metro Counselling"
        description="The page you're looking for doesn't exist. Return to Metro Counselling's homepage for professional counselling services in Surrey, BC."
      />
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-brand-teal sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Sorry, we couldn't find the page you're looking for. It might have been moved or renamed.
              </p>
            </div>
            <div className="mt-8 sm:border-l sm:border-transparent sm:pl-6">
              <div className="space-y-4">
                <Link
                  to="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-teal hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
                >
                  Go back home
                </Link>
                <Link
                  to="/contact-us"
                  className="inline-flex items-center ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-brand-teal bg-brand-teal/10 hover:bg-brand-teal/20"
                >
                  Contact us
                </Link>
              </div>
              <div className="mt-8">
                <h2 className="text-lg font-medium text-gray-900">Popular pages:</h2>
                <ul className="mt-4 space-y-2">
                  <li>
                    <Link to="/services" className="text-brand-teal hover:text-brand-teal-dark">
                      Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/therapies" className="text-brand-teal hover:text-brand-teal-dark">
                      Specialized Therapies
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" className="text-brand-teal hover:text-brand-teal-dark">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="text-brand-teal hover:text-brand-teal-dark">
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
